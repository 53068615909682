import useTheme from 'HOOKS/useTheme';

// Utils
import { IHyperlink } from 'UTILS/teamsInterface';

// Styles
import 'RESOURCES/styles/globalStyles.scss';

const HyperLink = ({ link, label }: IHyperlink) => {
  const { theme } = useTheme();
  return (
    <a
      className="no-underline"
      href={link}
      style={{
        color: theme.siteVariables?.colorScheme.brand.foreground,
        paddingLeft: '0.25rem',
      }}
      target="_blank"
      rel="noreferrer"
    >
      {label ? label : link}
    </a>
  );
};

export default HyperLink;
