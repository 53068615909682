import { useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Features
import teamsRoutes from 'FEATURES/teamsRoutes';

// Components
import Snackbar from 'COMPONENTS/snackbar/Snackbar';

// Constants
import { SNACKBAR_DURATION, SNACKBAR_TYPE } from 'CONSTANTS/enum';
import { LANGUAGE_PREFIX, TEAMS_LANGUAGE_RESOURCE } from 'CONSTANTS/teamsConstants';

// Hooks
import { useEffect } from 'react';
import useSnackbar from 'HOOKS/useSnackbar';

// Store
import { appSelector } from 'STORE/appSlice';

const TeamsRouter = () => {
  const teamsRoute = [...teamsRoutes];

  const showSnackbar = useSnackbar();
  const errorTimestamp = useSelector(appSelector.errorTimestamp);
  const t = useTranslation(TEAMS_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.COMMON).t;

  useEffect(() => {
    if (errorTimestamp) {
      showSnackbar({
        message: t('GENERIC_ERROR'),
        duration: SNACKBAR_DURATION.LONG,
        type: SNACKBAR_TYPE.DANGER,
      });
    }
  }, [errorTimestamp]);

  return (
    <BrowserRouter>
      <Routes>
        {teamsRoute.map(({ path, element }) => {
          return <Route path={path} element={element} key={`ROUTE-${path}`} />;
        })}
      </Routes>
      <Snackbar />
    </BrowserRouter>
  );
};

export default TeamsRouter;
