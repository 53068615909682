const languagesObjects = {
  en: [
    {
      label: 'Afar',
      value: 'aa',
    },
    {
      label: 'Amharic',
      value: 'am',
    },
    {
      label: 'Arabic',
      value: 'ar',
    },
    {
      label: 'Hausa',
      value: 'ha',
    },
    {
      label: 'Hebrew',
      value: 'he',
    },
    {
      label: 'Maltese',
      value: 'mt',
    },
    {
      label: 'Oromo',
      value: 'om',
    },
    {
      label: 'Somali',
      value: 'so',
    },
    {
      label: 'Tigrinya',
      value: 'ti',
    },
    {
      label: 'Cree',
      value: 'cr',
    },
    {
      label: 'Ojibwa',
      value: 'oj',
    },
    {
      label: 'Central Khmer',
      value: 'km',
    },
    {
      label: 'Vietnamese',
      value: 'vi',
    },
    {
      label: 'Chamorro',
      value: 'ch',
    },
    {
      label: 'Fijian',
      value: 'fj',
    },
    {
      label: 'Hiri Motu',
      value: 'ho',
    },
    {
      label: 'Indonesian',
      value: 'id',
    },
    {
      label: 'Javanese',
      value: 'jv',
    },
    {
      label: 'Malagasy',
      value: 'mg',
    },
    {
      label: 'Malay',
      value: 'ms',
    },
    {
      label: 'Maori',
      value: 'mi',
    },
    {
      label: 'Marshallese',
      value: 'mh',
    },
    {
      label: 'Nauru',
      value: 'na',
    },
    {
      label: 'Samoan',
      value: 'sm',
    },
    {
      label: 'Sundanese',
      value: 'su',
    },
    {
      label: 'Tagalog',
      value: 'tl',
    },
    {
      label: 'Tonga',
      value: 'to',
    },
    {
      label: 'Tahitian',
      value: 'ty',
    },
    {
      label: 'Aymara',
      value: 'ay',
    },
    {
      label: 'Ido',
      value: 'io',
    },
    {
      label: 'Volapük',
      value: 'vo',
    },
    {
      label: 'Bislama',
      value: 'bi',
    },
    {
      label: 'Haitian',
      value: 'ht',
    },
    {
      label: 'Sango',
      value: 'sg',
    },
    {
      label: 'Kannada',
      value: 'kn',
    },
    {
      label: 'Malayalam',
      value: 'ml',
    },
    {
      label: 'Tamil',
      value: 'ta',
    },
    {
      label: 'Telugu',
      value: 'te',
    },
    {
      label: 'Inupiaq',
      value: 'ik',
    },
    {
      label: 'Inuktitut',
      value: 'iu',
    },
    {
      label: 'Greenlandic',
      value: 'kl',
    },
    {
      label: 'Afrikaans',
      value: 'af',
    },
    {
      label: 'Albanian',
      value: 'sq',
    },
    {
      label: 'Aragonese',
      value: 'an',
    },
    {
      label: 'Armenian',
      value: 'hy',
    },
    {
      label: 'Assamese',
      value: 'as',
    },
    {
      label: 'Avestan',
      value: 'ae',
    },
    {
      label: 'Belarusian',
      value: 'be',
    },
    {
      label: 'Bengali',
      value: 'bn',
    },
    {
      label: 'Bosnian',
      value: 'bs',
    },
    {
      label: 'Breton',
      value: 'br',
    },
    {
      label: 'Bulgarian',
      value: 'bg',
    },
    {
      label: 'Catalan',
      value: 'ca',
    },
    {
      label: 'Corsican',
      value: 'co',
    },
    {
      label: 'Croatian',
      value: 'hr',
    },
    {
      label: 'Czech',
      value: 'cs',
    },
    {
      label: 'Danish',
      value: 'da',
    },
    {
      label: 'Divehi',
      value: 'dv',
    },
    {
      label: 'Dutch',
      value: 'nl',
    },
    {
      label: 'English',
      value: 'en',
    },
    {
      label: 'Faroese',
      value: 'fo',
    },
    {
      label: 'French',
      value: 'fr',
    },
    {
      label: 'Galician',
      value: 'gl',
    },
    {
      label: 'German',
      value: 'de',
    },
    {
      label: 'Greek',
      value: 'el',
    },
    {
      label: 'Gujarati',
      value: 'gu',
    },
    {
      label: 'Hindi',
      value: 'hi',
    },
    {
      label: 'Icelandic',
      value: 'is',
    },
    {
      label: 'Italian',
      value: 'it',
    },
    {
      label: 'Kashmiri',
      value: 'ks',
    },
    {
      label: 'Kurdish',
      value: 'ku',
    },
    {
      label: 'Luxembourgish',
      value: 'lb',
    },
    {
      label: 'Limburgan',
      value: 'li',
    },
    {
      label: 'Lithuanian',
      value: 'lt',
    },
    {
      label: 'Latvian',
      value: 'lv',
    },
    {
      label: 'Macedonian',
      value: 'mk',
    },
    {
      label: 'Marathi',
      value: 'mr',
    },
    {
      label: 'Nepali',
      value: 'ne',
    },
    {
      label: 'Norwegian',
      value: 'no',
    },
    {
      label: 'Ossetian',
      value: 'os',
    },
    {
      label: 'Punjabi',
      value: 'pa',
    },
    {
      label: 'Persian',
      value: 'fa',
    },
    {
      label: 'Polish',
      value: 'pl',
    },
    {
      label: 'Pashto',
      value: 'ps',
    },
    {
      label: 'Portuguese - Portugal',
      value: 'pt',
    },
    {
      label: 'Portuguese - Brazil',
      value: 'pb',
    },
    {
      label: 'Romanian',
      value: 'ro',
    },
    {
      label: 'Russian',
      value: 'ru',
    },
    {
      label: 'Sanskrit',
      value: 'sa',
    },
    {
      label: 'Serbian',
      value: 'sr',
    },
    {
      label: 'Sinhala',
      value: 'si',
    },
    {
      label: 'Slovak',
      value: 'sk',
    },
    {
      label: 'Slovenian',
      value: 'sl',
    },
    {
      label: 'Spanish - Spain',
      value: 'es',
    },
    {
      label: 'Spanish - Mexico',
      value: 'mx',
    },
    {
      label: 'Swedish',
      value: 'sv',
    },
    {
      label: 'Tajik',
      value: 'tg',
    },
    {
      label: 'Ukrainian',
      value: 'uk',
    },
    {
      label: 'Urdu',
      value: 'ur',
    },
    {
      label: 'Walloon',
      value: 'wa',
    },
    {
      label: 'Yiddish',
      value: 'yi',
    },
    {
      label: 'Japanese',
      value: 'ja',
    },
    {
      label: 'Georgian',
      value: 'ka',
    },
    {
      label: 'Korean',
      value: 'ko',
    },
    {
      label: 'Basque',
      value: 'eu',
    },
    {
      label: 'Mongolian',
      value: 'mn',
    },
    {
      label: 'Bambara',
      value: 'bm',
    },
    {
      label: 'Chewa',
      value: 'ny',
    },
    {
      label: 'Ewe',
      value: 'ee',
    },
    {
      label: 'Fulah',
      value: 'ff',
    },
    {
      label: 'Kinyarwanda',
      value: 'rw',
    },
    {
      label: 'Kongo',
      value: 'kg',
    },
    {
      label: 'Kuanyama',
      value: 'kj',
    },
    {
      label: 'Lingala',
      value: 'ln',
    },
    {
      label: 'Luba-Katanga',
      value: 'lu',
    },
    {
      label: 'North Ndebele',
      value: 'nd',
    },
    {
      label: 'Rundi',
      value: 'rn',
    },
    {
      label: 'Southern Sotho',
      value: 'st',
    },
    {
      label: 'Swahili',
      value: 'sw',
    },
    {
      label: 'Swati',
      value: 'ss',
    },
    {
      label: 'Tswana',
      value: 'tn',
    },
    {
      label: 'Tsonga',
      value: 'ts',
    },
    {
      label: 'Twi',
      value: 'tw',
    },
    {
      label: 'Venda',
      value: 've',
    },
    {
      label: 'Wolof',
      value: 'wo',
    },
    {
      label: 'Xhosa',
      value: 'xh',
    },
    {
      label: 'Yoruba',
      value: 'yo',
    },
    {
      label: 'Zulu',
      value: 'zu',
    },
    {
      label: 'Kanuri',
      value: 'kr',
    },
    {
      label: 'Avaric',
      value: 'av',
    },
    {
      label: 'Chechen',
      value: 'ce',
    },
    {
      label: 'Abkhazian',
      value: 'ab',
    },
    {
      label: 'Quechua',
      value: 'qu',
    },
    {
      label: 'Burmese',
      value: 'my',
    },
    {
      label: 'Chinese',
      value: 'zh',
    },
    {
      label: 'Dzongkha',
      value: 'dz',
    },
    {
      label: 'Sichuan Yi',
      value: 'ii',
    },
    {
      label: 'Tibetan',
      value: 'bo',
    },
    {
      label: 'Lao',
      value: 'lo',
    },
    {
      label: 'Thai',
      value: 'th',
    },
    {
      label: 'Zhuang',
      value: 'za',
    },
    {
      label: 'Guarani',
      value: 'gn',
    },
    {
      label: 'Azerbaijani',
      value: 'az',
    },
    {
      label: 'Bashkir',
      value: 'ba',
    },
    {
      label: 'Chuvash',
      value: 'cv',
    },
    {
      label: 'Kazakh',
      value: 'kk',
    },
    {
      label: 'Kirghiz',
      value: 'ky',
    },
    {
      label: 'Turkmen',
      value: 'tk',
    },
    {
      label: 'Turkish',
      value: 'tr',
    },
    {
      label: 'Tatar',
      value: 'tt',
    },
    {
      label: 'Uighur',
      value: 'ug',
    },
    {
      label: 'Uzbek',
      value: 'uz',
    },
    {
      label: 'Estonian',
      value: 'et',
    },
    {
      label: 'Finnish',
      value: 'fi',
    },
    {
      label: 'Hungarian',
      value: 'hu',
    },
    {
      label: 'Komi',
      value: 'kv',
    },
    {
      label: 'Northern Sami',
      value: 'se',
    },
    {
      label: 'Scandinavian',
      value: 'sc',
    },
  ],
  de: [
    {
      label: 'Afar',
      value: 'aa',
    },
    {
      label: 'Amharisch',
      value: 'am',
    },
    {
      label: 'Arabisch',
      value: 'ar',
    },
    {
      label: 'Hausa',
      value: 'ha',
    },
    {
      label: 'Hebräisch',
      value: 'he',
    },
    {
      label: 'Maltesisch',
      value: 'mt',
    },
    {
      label: 'Oromo',
      value: 'om',
    },
    {
      label: 'Somalisch',
      value: 'so',
    },
    {
      label: 'Tigrinisch',
      value: 'ti',
    },
    {
      label: 'Griechisch',
      value: 'cr',
    },
    {
      label: 'Ojibwa',
      value: 'oj',
    },
    {
      label: 'Zentral-Khmer',
      value: 'km',
    },
    {
      label: 'Vietnamesisch',
      value: 'vi',
    },
    {
      label: 'Chamorro',
      value: 'ch',
    },
    {
      label: 'Fidschianisch',
      value: 'fj',
    },
    {
      label: 'Hiri Motu',
      value: 'ho',
    },
    {
      label: 'Indonesisch',
      value: 'id',
    },
    {
      label: 'Javanisch',
      value: 'jv',
    },
    {
      label: 'Madagassisch',
      value: 'mg',
    },
    {
      label: 'Malaiisch',
      value: 'ms',
    },
    {
      label: 'Maori',
      value: 'mi',
    },
    {
      label: 'Marshallisch',
      value: 'mh',
    },
    {
      label: 'Nauru',
      value: 'na',
    },
    {
      label: 'Samoaner',
      value: 'sm',
    },
    {
      label: 'Sundanisch',
      value: 'su',
    },
    {
      label: 'Tagalog',
      value: 'tl',
    },
    {
      label: 'Tonga',
      value: 'to',
    },
    {
      label: 'Tahitianisch',
      value: 'ty',
    },
    {
      label: 'Aymara',
      value: 'ay',
    },
    {
      label: 'Ido',
      value: 'io',
    },
    {
      label: 'Volapük',
      value: 'vo',
    },
    {
      label: 'Bislama',
      value: 'bi',
    },
    {
      label: 'Haitianisch',
      value: 'ht',
    },
    {
      label: 'Sango',
      value: 'sg',
    },
    {
      label: 'Kannada',
      value: 'kn',
    },
    {
      label: 'Malayalam',
      value: 'ml',
    },
    {
      label: 'Tamilisch',
      value: 'ta',
    },
    {
      label: 'Telugu',
      value: 'te',
    },
    {
      label: 'Inupiaq',
      value: 'ik',
    },
    {
      label: 'Inuktitut',
      value: 'iu',
    },
    {
      label: 'Grönländisch',
      value: 'kl',
    },
    {
      label: 'Afrikaans',
      value: 'af',
    },
    {
      label: 'Albanisch',
      value: 'sq',
    },
    {
      label: 'Aragonesisch',
      value: 'an',
    },
    {
      label: 'Armenisch',
      value: 'hy',
    },
    {
      label: 'Assamesisch',
      value: 'as',
    },
    {
      label: 'Avestisch',
      value: 'ae',
    },
    {
      label: 'Weißrussisch',
      value: 'be',
    },
    {
      label: 'Bengalisch',
      value: 'bn',
    },
    {
      label: 'Bosnisch',
      value: 'bs',
    },
    {
      label: 'Bretonisch',
      value: 'br',
    },
    {
      label: 'Bulgarisch',
      value: 'bg',
    },
    {
      label: 'Katalanisch',
      value: 'ca',
    },
    {
      label: 'Korsisch',
      value: 'co',
    },
    {
      label: 'Kroatisch',
      value: 'hr',
    },
    {
      label: 'Tschechisch',
      value: 'cs',
    },
    {
      label: 'Dänisch',
      value: 'da',
    },
    {
      label: 'Divehi',
      value: 'dv',
    },
    {
      label: 'Niederländisch',
      value: 'nl',
    },
    {
      label: 'Englisch',
      value: 'en',
    },
    {
      label: 'Färöisch',
      value: 'fo',
    },
    {
      label: 'Französisch',
      value: 'fr',
    },
    {
      label: 'Galizisch',
      value: 'gl',
    },
    {
      label: 'Deutsch',
      value: 'de',
    },
    {
      label: 'Griechisch',
      value: 'el',
    },
    {
      label: 'Gujarati',
      value: 'gu',
    },
    {
      label: 'Hindi',
      value: 'hi',
    },
    {
      label: 'Isländisch',
      value: 'is',
    },
    {
      label: 'Italienisch',
      value: 'it',
    },
    {
      label: 'Kaschmiri',
      value: 'ks',
    },
    {
      label: 'Kurdisch',
      value: 'ku',
    },
    {
      label: 'Luxemburgisch',
      value: 'lb',
    },
    {
      label: 'Limburgerisch',
      value: 'li',
    },
    {
      label: 'Litauisch',
      value: 'lt',
    },
    {
      label: 'Lettisch',
      value: 'lv',
    },
    {
      label: 'Mazedonisch',
      value: 'mk',
    },
    {
      label: 'Marathi',
      value: 'mr',
    },
    {
      label: 'Nepalesisch',
      value: 'ne',
    },
    {
      label: 'Norwegisch',
      value: 'no',
    },
    {
      label: 'Ossetisch',
      value: 'os',
    },
    {
      label: 'Punjabi',
      value: 'pa',
    },
    {
      label: 'Persisch',
      value: 'fa',
    },
    {
      label: 'Polnisch',
      value: 'pl',
    },
    {
      label: 'Paschtu',
      value: 'ps',
    },
    {
      label: 'Portugiesisch - Portugal',
      value: 'pt',
    },
    {
      label: 'Portugiesisch - Brasilien',
      value: 'pb',
    },
    {
      label: 'Rumänisch',
      value: 'ro',
    },
    {
      label: 'Russisch',
      value: 'ru',
    },
    {
      label: 'Sanskrit',
      value: 'sa',
    },
    {
      label: 'Serbisch',
      value: 'sr',
    },
    {
      label: 'Singhalesisch',
      value: 'si',
    },
    {
      label: 'Slowakisch',
      value: 'sk',
    },
    {
      label: 'Slowenisch',
      value: 'sl',
    },
    {
      label: 'Spanisch - Spanien',
      value: 'es',
    },
    {
      label: 'Spanisch - Mexiko',
      value: 'mx',
    },
    {
      label: 'Schwedisch',
      value: 'sv',
    },
    {
      label: 'Tadschikisch',
      value: 'tg',
    },
    {
      label: 'Ukrainisch',
      value: 'uk',
    },
    {
      label: 'Urdu',
      value: 'ur',
    },
    {
      label: 'Wallonisch',
      value: 'wa',
    },
    {
      label: 'Jiddisch',
      value: 'yi',
    },
    {
      label: 'Japanisch',
      value: 'ja',
    },
    {
      label: 'Georgisch',
      value: 'ka',
    },
    {
      label: 'Koreanisch',
      value: 'ko',
    },
    {
      label: 'Baskisch',
      value: 'eu',
    },
    {
      label: 'Mongolisch',
      value: 'mn',
    },
    {
      label: 'Bambara',
      value: 'bm',
    },
    {
      label: 'Chewa',
      value: 'ny',
    },
    {
      label: 'Ewe',
      value: 'ee',
    },
    {
      label: 'Fulah',
      value: 'ff',
    },
    {
      label: 'Kinyarwanda',
      value: 'rw',
    },
    {
      label: 'Kongo',
      value: 'kg',
    },
    {
      label: 'Kuanyama',
      value: 'kj',
    },
    {
      label: 'Lingala',
      value: 'ln',
    },
    {
      label: 'Luba-Katanga',
      value: 'lu',
    },
    {
      label: 'Nord-Ndebele',
      value: 'nd',
    },
    {
      label: 'Rundi',
      value: 'rn',
    },
    {
      label: 'Süd-Sotho',
      value: 'st',
    },
    {
      label: 'Suaheli',
      value: 'sw',
    },
    {
      label: 'Swati',
      value: 'ss',
    },
    {
      label: 'Tswana',
      value: 'tn',
    },
    {
      label: 'Tsonga',
      value: 'ts',
    },
    {
      label: 'Twi',
      value: 'tw',
    },
    {
      label: 'Venda',
      value: 've',
    },
    {
      label: 'Wolof',
      value: 'wo',
    },
    {
      label: 'Xhosa',
      value: 'xh',
    },
    {
      label: 'Yoruba',
      value: 'yo',
    },
    {
      label: 'Zulu',
      value: 'zu',
    },
    {
      label: 'Kanuri',
      value: 'kr',
    },
    {
      label: 'Awarisch',
      value: 'av',
    },
    {
      label: 'Tschetschenisch',
      value: 'ce',
    },
    {
      label: 'Abchasisch',
      value: 'ab',
    },
    {
      label: 'Ketschua',
      value: 'qu',
    },
    {
      label: 'Birmanisch',
      value: 'my',
    },
    {
      label: 'Chinesisch',
      value: 'zh',
    },
    {
      label: 'Dzongkha',
      value: 'dz',
    },
    {
      label: 'Sichuanisch Yi',
      value: 'ii',
    },
    {
      label: 'Tibetisch',
      value: 'bo',
    },
    {
      label: 'Laotisch',
      value: 'lo',
    },
    {
      label: 'Thailändisch',
      value: 'th',
    },
    {
      label: 'Zhuang',
      value: 'za',
    },
    {
      label: 'Guarani',
      value: 'gn',
    },
    {
      label: 'Aserbaidschanisch',
      value: 'az',
    },
    {
      label: 'Baschkirisch',
      value: 'ba',
    },
    {
      label: 'Tschuwaschisch',
      value: 'cv',
    },
    {
      label: 'Kasachisch',
      value: 'kk',
    },
    {
      label: 'Kirgisisch',
      value: 'ky',
    },
    {
      label: 'Turkmenisch',
      value: 'tk',
    },
    {
      label: 'Türkisch',
      value: 'tr',
    },
    {
      label: 'Tatarisch',
      value: 'tt',
    },
    {
      label: 'Uigurisch',
      value: 'ug',
    },
    {
      label: 'Usbekisch',
      value: 'uz',
    },
    {
      label: 'Estnisch',
      value: 'et',
    },
    {
      label: 'Finnisch',
      value: 'fi',
    },
    {
      label: 'Ungarisch',
      value: 'hu',
    },
    {
      label: 'Komi',
      value: 'kv',
    },
    {
      label: 'Nordsamisch',
      value: 'se',
    },
    {
      label: 'Skandinavisch',
      value: 'sc',
    },
  ],
  es: [
    {
      label: 'Afar',
      value: 'aa',
    },
    {
      label: 'Amárico',
      value: 'am',
    },
    {
      label: 'Árabe',
      value: 'ar',
    },
    {
      label: 'Hausa',
      value: 'ha',
    },
    {
      label: 'Hebreo',
      value: 'he',
    },
    {
      label: 'Maltés',
      value: 'mt',
    },
    {
      label: 'Oromo',
      value: 'om',
    },
    {
      label: 'Somalí',
      value: 'so',
    },
    {
      label: 'Tigriña',
      value: 'ti',
    },
    {
      label: 'Cree',
      value: 'cr',
    },
    {
      label: 'Ojibwa',
      value: 'oj',
    },
    {
      label: 'Jemer central',
      value: 'km',
    },
    {
      label: 'Vietnamita',
      value: 'vi',
    },
    {
      label: 'Chamorro',
      value: 'ch',
    },
    {
      label: 'Fijiano',
      value: 'fj',
    },
    {
      label: 'Hiri Motu',
      value: 'ho',
    },
    {
      label: 'Indonesio',
      value: 'id',
    },
    {
      label: 'Javanés',
      value: 'jv',
    },
    {
      label: 'Malgache',
      value: 'mg',
    },
    {
      label: 'Malayo',
      value: 'ms',
    },
    {
      label: 'Maorí',
      value: 'mi',
    },
    {
      label: 'Marshaliano',
      value: 'mh',
    },
    {
      label: 'Nauru',
      value: 'na',
    },
    {
      label: 'Samoano',
      value: 'sm',
    },
    {
      label: 'Sundanés',
      value: 'su',
    },
    {
      label: 'Tagalo',
      value: 'tl',
    },
    {
      label: 'Tonga',
      value: 'to',
    },
    {
      label: 'Tahitiano',
      value: 'ty',
    },
    {
      label: 'Aymara',
      value: 'ay',
    },
    {
      label: 'Ido',
      value: 'io',
    },
    {
      label: 'Volapük',
      value: 'vo',
    },
    {
      label: 'Bislama',
      value: 'bi',
    },
    {
      label: 'Haitiano',
      value: 'ht',
    },
    {
      label: 'Sango',
      value: 'sg',
    },
    {
      label: 'Kannada',
      value: 'kn',
    },
    {
      label: 'Malayalam',
      value: 'ml',
    },
    {
      label: 'Tamil',
      value: 'ta',
    },
    {
      label: 'Telugu',
      value: 'te',
    },
    {
      label: 'Inupiaq',
      value: 'ik',
    },
    {
      label: 'Inuktitut',
      value: 'iu',
    },
    {
      label: 'Groenlandés',
      value: 'kl',
    },
    {
      label: 'Afrikáans',
      value: 'af',
    },
    {
      label: 'Albanés',
      value: 'sq',
    },
    {
      label: 'Aragonés',
      value: 'an',
    },
    {
      label: 'Armenio',
      value: 'hy',
    },
    {
      label: 'Asamés',
      value: 'as',
    },
    {
      label: 'Avestán',
      value: 'ae',
    },
    {
      label: 'Bielorruso',
      value: 'be',
    },
    {
      label: 'Bengalí',
      value: 'bn',
    },
    {
      label: 'Bosnio',
      value: 'bs',
    },
    {
      label: 'Bretón',
      value: 'br',
    },
    {
      label: 'Búlgaro',
      value: 'bg',
    },
    {
      label: 'Catalán',
      value: 'ca',
    },
    {
      label: 'Corso',
      value: 'co',
    },
    {
      label: 'Croata',
      value: 'hr',
    },
    {
      label: 'Checo',
      value: 'cs',
    },
    {
      label: 'Danés',
      value: 'da',
    },
    {
      label: 'Divehi',
      value: 'dv',
    },
    {
      label: 'Holandés',
      value: 'nl',
    },
    {
      label: 'Inglés',
      value: 'en',
    },
    {
      label: 'Feroés',
      value: 'fo',
    },
    {
      label: 'Francés',
      value: 'fr',
    },
    {
      label: 'Gallego',
      value: 'gl',
    },
    {
      label: 'Alemán',
      value: 'de',
    },
    {
      label: 'Griego',
      value: 'el',
    },
    {
      label: 'Gujarati',
      value: 'gu',
    },
    {
      label: 'Hindi',
      value: 'hi',
    },
    {
      label: 'Islandés',
      value: 'is',
    },
    {
      label: 'Italiano',
      value: 'it',
    },
    {
      label: 'Cachemira',
      value: 'ks',
    },
    {
      label: 'Kurdo',
      value: 'ku',
    },
    {
      label: 'Luxemburgués',
      value: 'lb',
    },
    {
      label: 'Limburgués',
      value: 'li',
    },
    {
      label: 'Lituano',
      value: 'lt',
    },
    {
      label: 'Letón',
      value: 'lv',
    },
    {
      label: 'Macedonio',
      value: 'mk',
    },
    {
      label: 'Marathi',
      value: 'mr',
    },
    {
      label: 'Nepalí',
      value: 'ne',
    },
    {
      label: 'Noruego',
      value: 'no',
    },
    {
      label: 'Osetio',
      value: 'os',
    },
    {
      label: 'Punjabi',
      value: 'pa',
    },
    {
      label: 'Persa',
      value: 'fa',
    },
    {
      label: 'Polaco',
      value: 'pl',
    },
    {
      label: 'Pashto',
      value: 'ps',
    },
    {
      label: 'Portugués de Portugal',
      value: 'pt',
    },
    {
      label: 'Portugués de Brasil',
      value: 'pb',
    },
    {
      label: 'Rumano',
      value: 'ro',
    },
    {
      label: 'Ruso',
      value: 'ru',
    },
    {
      label: 'Sánscrito',
      value: 'sa',
    },
    {
      label: 'Serbio',
      value: 'sr',
    },
    {
      label: 'Cingalés',
      value: 'si',
    },
    {
      label: 'Eslovaco',
      value: 'sk',
    },
    {
      label: 'Esloveno',
      value: 'sl',
    },
    {
      label: 'Español - España',
      value: 'es',
    },
    {
      label: 'Español - México',
      value: 'mx',
    },
    {
      label: 'Sueco',
      value: 'sv',
    },
    {
      label: 'Tayiko',
      value: 'tg',
    },
    {
      label: 'Ucraniano',
      value: 'uk',
    },
    {
      label: 'Urdu',
      value: 'ur',
    },
    {
      label: 'Valón',
      value: 'wa',
    },
    {
      label: 'Yiddish',
      value: 'yi',
    },
    {
      label: 'Japonés',
      value: 'ja',
    },
    {
      label: 'Georgiano',
      value: 'ka',
    },
    {
      label: 'Coreano',
      value: 'ko',
    },
    {
      label: 'Vasco',
      value: 'eu',
    },
    {
      label: 'Mongol',
      value: 'mn',
    },
    {
      label: 'Bambara',
      value: 'bm',
    },
    {
      label: 'Chewa',
      value: 'ny',
    },
    {
      label: 'Ewe',
      value: 'ee',
    },
    {
      label: 'Fulah',
      value: 'ff',
    },
    {
      label: 'Kinyarwanda',
      value: 'rw',
    },
    {
      label: 'Kongo',
      value: 'kg',
    },
    {
      label: 'Kuanyama',
      value: 'kj',
    },
    {
      label: 'Lingala',
      value: 'ln',
    },
    {
      label: 'Luba-Katanga',
      value: 'lu',
    },
    {
      label: 'Ndebele del Norte',
      value: 'nd',
    },
    {
      label: 'Rundi',
      value: 'rn',
    },
    {
      label: 'Sotho del Sur',
      value: 'st',
    },
    {
      label: 'Swahili',
      value: 'sw',
    },
    {
      label: 'Swati',
      value: 'ss',
    },
    {
      label: 'Tswana',
      value: 'tn',
    },
    {
      label: 'Tsonga',
      value: 'ts',
    },
    {
      label: 'Twi',
      value: 'tw',
    },
    {
      label: 'Venda',
      value: 've',
    },
    {
      label: 'Wolof',
      value: 'wo',
    },
    {
      label: 'Xhosa',
      value: 'xh',
    },
    {
      label: 'Yoruba',
      value: 'yo',
    },
    {
      label: 'Zulú',
      value: 'zu',
    },
    {
      label: 'Kanuri',
      value: 'kr',
    },
    {
      label: 'Avarico',
      value: 'av',
    },
    {
      label: 'Checheno',
      value: 'ce',
    },
    {
      label: 'Abjasio',
      value: 'ab',
    },
    {
      label: 'Quechua',
      value: 'qu',
    },
    {
      label: 'Birmano',
      value: 'my',
    },
    {
      label: 'Chino',
      value: 'zh',
    },
    {
      label: 'Dzongkha',
      value: 'dz',
    },
    {
      label: 'Yi de Sichuan',
      value: 'ii',
    },
    {
      label: 'Tibetano',
      value: 'bo',
    },
    {
      label: 'Lao',
      value: 'lo',
    },
    {
      label: 'Tailandés',
      value: 'th',
    },
    {
      label: 'Zhuang',
      value: 'za',
    },
    {
      label: 'Guaraní',
      value: 'gn',
    },
    {
      label: 'Azerbaiyano',
      value: 'az',
    },
    {
      label: 'Bashkir',
      value: 'ba',
    },
    {
      label: 'Chuvash',
      value: 'cv',
    },
    {
      label: 'Kazajo',
      value: 'kk',
    },
    {
      label: 'Kirghiz',
      value: 'ky',
    },
    {
      label: 'Turcomano',
      value: 'tk',
    },
    {
      label: 'Turco',
      value: 'tr',
    },
    {
      label: 'Tártaro',
      value: 'tt',
    },
    {
      label: 'Uigur',
      value: 'ug',
    },
    {
      label: 'Uzbeko',
      value: 'uz',
    },
    {
      label: 'Estonio',
      value: 'et',
    },
    {
      label: 'Finlandés',
      value: 'fi',
    },
    {
      label: 'Húngaro',
      value: 'hu',
    },
    {
      label: 'Komi',
      value: 'kv',
    },
    {
      label: 'Sami del Norte',
      value: 'se',
    },
    {
      label: 'Escandinavo',
      value: 'sc',
    },
  ],
  fr: [
    {
      label: 'Afar',
      value: 'aa',
    },
    {
      label: 'Amharique',
      value: 'am',
    },
    {
      label: 'Arabe',
      value: 'ar',
    },
    {
      label: 'Haoussa',
      value: 'ha',
    },
    {
      label: 'Hébreu',
      value: 'he',
    },
    {
      label: 'Maltais',
      value: 'mt',
    },
    {
      label: 'Oromo',
      value: 'om',
    },
    {
      label: 'Somali',
      value: 'so',
    },
    {
      label: 'Tigrinya',
      value: 'ti',
    },
    {
      label: 'Cri',
      value: 'cr',
    },
    {
      label: 'Ojibwa',
      value: 'oj',
    },
    {
      label: 'Khmer central',
      value: 'km',
    },
    {
      label: 'Vietnamien',
      value: 'vi',
    },
    {
      label: 'Chamorro',
      value: 'ch',
    },
    {
      label: 'Fidjien',
      value: 'fj',
    },
    {
      label: 'Hiri Motu',
      value: 'ho',
    },
    {
      label: 'Indonésien',
      value: 'id',
    },
    {
      label: 'Javanais',
      value: 'jv',
    },
    {
      label: 'Malgache',
      value: 'mg',
    },
    {
      label: 'Malais',
      value: 'ms',
    },
    {
      label: 'Maori',
      value: 'mi',
    },
    {
      label: 'Marshallais',
      value: 'mh',
    },
    {
      label: 'Nauru',
      value: 'na',
    },
    {
      label: 'Samoan',
      value: 'sm',
    },
    {
      label: 'Sundanais',
      value: 'su',
    },
    {
      label: 'Tagalog',
      value: 'tl',
    },
    {
      label: 'Tonga',
      value: 'to',
    },
    {
      label: 'Tahitien',
      value: 'ty',
    },
    {
      label: 'Aymara',
      value: 'ay',
    },
    {
      label: 'Ido',
      value: 'io',
    },
    {
      label: 'Volapük',
      value: 'vo',
    },
    {
      label: 'Bislama',
      value: 'bi',
    },
    {
      label: 'Haïtien',
      value: 'ht',
    },
    {
      label: 'Sango',
      value: 'sg',
    },
    {
      label: 'Kannada',
      value: 'kn',
    },
    {
      label: 'Malayalam',
      value: 'ml',
    },
    {
      label: 'Tamil',
      value: 'ta',
    },
    {
      label: 'Telugu',
      value: 'te',
    },
    {
      label: 'Inupiaq',
      value: 'ik',
    },
    {
      label: 'Inuktitut',
      value: 'iu',
    },
    {
      label: 'Groenlandais',
      value: 'kl',
    },
    {
      label: 'Afrikaans',
      value: 'af',
    },
    {
      label: 'Albanais',
      value: 'sq',
    },
    {
      label: 'Aragonais',
      value: 'an',
    },
    {
      label: 'Arménien',
      value: 'hy',
    },
    {
      label: 'Assamais',
      value: 'as',
    },
    {
      label: 'Avestan',
      value: 'ae',
    },
    {
      label: 'Biélorusse',
      value: 'be',
    },
    {
      label: 'Bengali',
      value: 'bn',
    },
    {
      label: 'Bosniaque',
      value: 'bs',
    },
    {
      label: 'Breton',
      value: 'br',
    },
    {
      label: 'Bulgare',
      value: 'bg',
    },
    {
      label: 'Catalan',
      value: 'ca',
    },
    {
      label: 'corse',
      value: 'co',
    },
    {
      label: 'Croate',
      value: 'hr',
    },
    {
      label: 'Tchèque',
      value: 'cs',
    },
    {
      label: 'Danois',
      value: 'da',
    },
    {
      label: 'Divehi',
      value: 'dv',
    },
    {
      label: 'Néerlandais',
      value: 'nl',
    },
    {
      label: 'Anglais',
      value: 'en',
    },
    {
      label: 'Féroïen',
      value: 'fo',
    },
    {
      label: 'Français',
      value: 'fr',
    },
    {
      label: 'Galicien',
      value: 'gl',
    },
    {
      label: 'Allemand',
      value: 'de',
    },
    {
      label: 'Grec',
      value: 'el',
    },
    {
      label: 'Gujarati',
      value: 'gu',
    },
    {
      label: 'Hindi',
      value: 'hi',
    },
    {
      label: 'islandais',
      value: 'is',
    },
    {
      label: 'Italien',
      value: 'it',
    },
    {
      label: 'Cachemiri',
      value: 'ks',
    },
    {
      label: 'Kurde',
      value: 'ku',
    },
    {
      label: 'Luxembourgeois',
      value: 'lb',
    },
    {
      label: 'Limbourgeois',
      value: 'li',
    },
    {
      label: 'Lituanien',
      value: 'lt',
    },
    {
      label: 'Letton',
      value: 'lv',
    },
    {
      label: 'Macédonien',
      value: 'mk',
    },
    {
      label: 'Marathi',
      value: 'mr',
    },
    {
      label: 'Népalais',
      value: 'ne',
    },
    {
      label: 'Norvégien',
      value: 'no',
    },
    {
      label: 'Ossète',
      value: 'os',
    },
    {
      label: 'Pendjabi',
      value: 'pa',
    },
    {
      label: 'Persan',
      value: 'fa',
    },
    {
      label: 'Polonais',
      value: 'pl',
    },
    {
      label: 'Pachtoune',
      value: 'ps',
    },
    {
      label: 'Portugais - Portugal',
      value: 'pt',
    },
    {
      label: 'Portugais - Brésil',
      value: 'pb',
    },
    {
      label: 'Roumain',
      value: 'ro',
    },
    {
      label: 'Russe',
      value: 'ru',
    },
    {
      label: 'Sanskrit',
      value: 'sa',
    },
    {
      label: 'Serbe',
      value: 'sr',
    },
    {
      label: 'Sinhala',
      value: 'si',
    },
    {
      label: 'Slovaque',
      value: 'sk',
    },
    {
      label: 'Slovène',
      value: 'sl',
    },
    {
      label: 'Espagnol - Espagne',
      value: 'es',
    },
    {
      label: 'Espagnol - Mexique',
      value: 'mx',
    },
    {
      label: 'Suédois',
      value: 'sv',
    },
    {
      label: 'Tadjik',
      value: 'tg',
    },
    {
      label: 'Ukrainien',
      value: 'uk',
    },
    {
      label: 'Urdu',
      value: 'ur',
    },
    {
      label: 'Wallon',
      value: 'wa',
    },
    {
      label: 'Yiddish',
      value: 'yi',
    },
    {
      label: 'Japonais',
      value: 'ja',
    },
    {
      label: 'Géorgien',
      value: 'ka',
    },
    {
      label: 'Coréen',
      value: 'ko',
    },
    {
      label: 'Basque',
      value: 'eu',
    },
    {
      label: 'Mongol',
      value: 'mn',
    },
    {
      label: 'Bambara',
      value: 'bm',
    },
    {
      label: 'Chewa',
      value: 'ny',
    },
    {
      label: 'Ewe',
      value: 'ee',
    },
    {
      label: 'Fulah',
      value: 'ff',
    },
    {
      label: 'Kinyarwanda',
      value: 'rw',
    },
    {
      label: 'Kongo',
      value: 'kg',
    },
    {
      label: 'Kuanyama',
      value: 'kj',
    },
    {
      label: 'Lingala',
      value: 'ln',
    },
    {
      label: 'Luba-Katanga',
      value: 'lu',
    },
    {
      label: 'North Ndebele',
      value: 'nd',
    },
    {
      label: 'Rundi',
      value: 'rn',
    },
    {
      label: 'Sotho du Sud',
      value: 'st',
    },
    {
      label: 'Swahili',
      value: 'sw',
    },
    {
      label: 'Swati',
      value: 'ss',
    },
    {
      label: 'Tswana',
      value: 'tn',
    },
    {
      label: 'Tsonga',
      value: 'ts',
    },
    {
      label: 'Twi',
      value: 'tw',
    },
    {
      label: 'Venda',
      value: 've',
    },
    {
      label: 'Wolof',
      value: 'wo',
    },
    {
      label: 'Xhosa',
      value: 'xh',
    },
    {
      label: 'Yoruba',
      value: 'yo',
    },
    {
      label: 'Zulu',
      value: 'zu',
    },
    {
      label: 'Kanuri',
      value: 'kr',
    },
    {
      label: 'Avaric',
      value: 'av',
    },
    {
      label: 'Tchétchène',
      value: 'ce',
    },
    {
      label: 'Abkhazian',
      value: 'ab',
    },
    {
      label: 'Quechua',
      value: 'qu',
    },
    {
      label: 'Birmane',
      value: 'my',
    },
    {
      label: 'Chinois',
      value: 'zh',
    },
    {
      label: 'Dzongkha',
      value: 'dz',
    },
    {
      label: 'Yi du Sichuan',
      value: 'ii',
    },
    {
      label: 'Tibétain',
      value: 'bo',
    },
    {
      label: 'Lao',
      value: 'lo',
    },
    {
      label: 'Thaïlandais',
      value: 'th',
    },
    {
      label: 'Zhuang',
      value: 'za',
    },
    {
      label: 'Guarani',
      value: 'gn',
    },
    {
      label: 'Azerbaïdjanais',
      value: 'az',
    },
    {
      label: 'Bashkir',
      value: 'ba',
    },
    {
      label: 'Tchouvache',
      value: 'cv',
    },
    {
      label: 'Kazakh',
      value: 'kk',
    },
    {
      label: 'Kirghiz',
      value: 'ky',
    },
    {
      label: 'Turkmène',
      value: 'tk',
    },
    {
      label: 'Turc',
      value: 'tr',
    },
    {
      label: 'Tatar',
      value: 'tt',
    },
    {
      label: 'Ouïgour',
      value: 'ug',
    },
    {
      label: 'Ouzbek',
      value: 'uz',
    },
    {
      label: 'Estonien',
      value: 'et',
    },
    {
      label: 'Finlandais',
      value: 'fi',
    },
    {
      label: 'Hongrois',
      value: 'hu',
    },
    {
      label: 'Komi',
      value: 'kv',
    },
    {
      label: 'Sami du Nord',
      value: 'se',
    },
    {
      label: 'Scandinave',
      value: 'sc',
    },
  ],
  pt: [
    {
      label: 'Afar',
      value: 'aa',
    },
    {
      label: 'Amárico',
      value: 'am',
    },
    {
      label: 'árabe',
      value: 'ar',
    },
    {
      label: 'Hausa',
      value: 'ha',
    },
    {
      label: 'Hebraico',
      value: 'he',
    },
    {
      label: 'Maltês',
      value: 'mt',
    },
    {
      label: 'Oromo',
      value: 'om',
    },
    {
      label: 'Somali',
      value: 'so',
    },
    {
      label: 'Tigrinya',
      value: 'ti',
    },
    {
      label: 'Cree',
      value: 'cr',
    },
    {
      label: 'Ojibwa',
      value: 'oj',
    },
    {
      label: 'Khmer Central',
      value: 'km',
    },
    {
      label: 'Vietnamita',
      value: 'vi',
    },
    {
      label: 'Chamorro',
      value: 'ch',
    },
    {
      label: 'Fijianas',
      value: 'fj',
    },
    {
      label: 'Hiri Motu',
      value: 'ho',
    },
    {
      label: 'Indonésio',
      value: 'id',
    },
    {
      label: 'Javanês',
      value: 'jv',
    },
    {
      label: 'Malgaxe',
      value: 'mg',
    },
    {
      label: 'Malaio',
      value: 'ms',
    },
    {
      label: 'Maori',
      value: 'mi',
    },
    {
      label: 'Marshallese',
      value: 'mh',
    },
    {
      label: 'Nauru',
      value: 'na',
    },
    {
      label: 'Samoan',
      value: 'sm',
    },
    {
      label: 'Sundanese',
      value: 'su',
    },
    {
      label: 'Tagalog',
      value: 'tl',
    },
    {
      label: 'Tonga',
      value: 'to',
    },
    {
      label: 'Tahitiano',
      value: 'ty',
    },
    {
      label: 'Aymara',
      value: 'ay',
    },
    {
      label: 'Ido',
      value: 'io',
    },
    {
      label: 'Volapük',
      value: 'vo',
    },
    {
      label: 'Bislama',
      value: 'bi',
    },
    {
      label: 'Haitiano',
      value: 'ht',
    },
    {
      label: 'Sango',
      value: 'sg',
    },
    {
      label: 'Kannada',
      value: 'kn',
    },
    {
      label: 'Malayalam',
      value: 'ml',
    },
    {
      label: 'Tâmil',
      value: 'ta',
    },
    {
      label: 'Telugu',
      value: 'te',
    },
    {
      label: 'Inupiaq',
      value: 'ik',
    },
    {
      label: 'Inuktitut',
      value: 'iu',
    },
    {
      label: 'Gronelândia',
      value: 'kl',
    },
    {
      label: 'Afrikaans',
      value: 'af',
    },
    {
      label: 'Albanês',
      value: 'sq',
    },
    {
      label: 'Aragonês',
      value: 'an',
    },
    {
      label: 'Arménio',
      value: 'hy',
    },
    {
      label: 'Assamês',
      value: 'as',
    },
    {
      label: 'Avestan',
      value: 'ae',
    },
    {
      label: 'Bielorrusso',
      value: 'be',
    },
    {
      label: 'Bengali',
      value: 'bn',
    },
    {
      label: 'Bósnio',
      value: 'bs',
    },
    {
      label: 'Bretão',
      value: 'br',
    },
    {
      label: 'Búlgaro',
      value: 'bg',
    },
    {
      label: 'Catalão',
      value: 'ca',
    },
    {
      label: 'Córsego',
      value: 'co',
    },
    {
      label: 'Croata',
      value: 'hr',
    },
    {
      label: 'Checo',
      value: 'cs',
    },
    {
      label: 'Dinamarquês',
      value: 'da',
    },
    {
      label: 'Divehi',
      value: 'dv',
    },
    {
      label: 'Holandês',
      value: 'nl',
    },
    {
      label: 'Inglês',
      value: 'en',
    },
    {
      label: 'Faroês',
      value: 'fo',
    },
    {
      label: 'Francês',
      value: 'fr',
    },
    {
      label: 'Galego',
      value: 'gl',
    },
    {
      label: 'Alemão',
      value: 'de',
    },
    {
      label: 'Grego',
      value: 'el',
    },
    {
      label: 'Gujarati',
      value: 'gu',
    },
    {
      label: 'Hindi',
      value: 'hi',
    },
    {
      label: 'Islandês',
      value: 'is',
    },
    {
      label: 'Italiano',
      value: 'it',
    },
    {
      label: 'Kashmiri',
      value: 'ks',
    },
    {
      label: 'Curdo',
      value: 'ku',
    },
    {
      label: 'Luxemburguês',
      value: 'lb',
    },
    {
      label: 'Limburgan',
      value: 'li',
    },
    {
      label: 'Lituano',
      value: 'lt',
    },
    {
      label: 'Letão',
      value: 'lv',
    },
    {
      label: 'Macedónio',
      value: 'mk',
    },
    {
      label: 'Marathi',
      value: 'mr',
    },
    {
      label: 'Nepalês',
      value: 'ne',
    },
    {
      label: 'Norueguês',
      value: 'no',
    },
    {
      label: 'Osseciano',
      value: 'os',
    },
    {
      label: 'Punjabi',
      value: 'pa',
    },
    {
      label: 'Persa',
      value: 'fa',
    },
    {
      label: 'Polaco',
      value: 'pl',
    },
    {
      label: 'Pashto',
      value: 'ps',
    },
    {
      label: 'Português - Portugal',
      value: 'pt',
    },
    {
      label: 'Português - Brasil',
      value: 'pb',
    },
    {
      label: 'Romeno',
      value: 'ro',
    },
    {
      label: 'Russo',
      value: 'ru',
    },
    {
      label: 'Sânscrito',
      value: 'sa',
    },
    {
      label: 'Sérvio',
      value: 'sr',
    },
    {
      label: 'Sinhala',
      value: 'si',
    },
    {
      label: 'Eslovaco',
      value: 'sk',
    },
    {
      label: 'Esloveno',
      value: 'sl',
    },
    {
      label: 'Espanhol - Espanha',
      value: 'es',
    },
    {
      label: 'Espanhol - México',
      value: 'mx',
    },
    {
      label: 'Sueco',
      value: 'sv',
    },
    {
      label: 'Tajik',
      value: 'tg',
    },
    {
      label: 'Ucraniano',
      value: 'uk',
    },
    {
      label: 'Urdu',
      value: 'ur',
    },
    {
      label: 'Valão',
      value: 'wa',
    },
    {
      label: 'Yiddish',
      value: 'yi',
    },
    {
      label: 'Japonês',
      value: 'ja',
    },
    {
      label: 'Georgiano',
      value: 'ka',
    },
    {
      label: 'Coreano',
      value: 'ko',
    },
    {
      label: 'Basco',
      value: 'eu',
    },
    {
      label: 'Mongol',
      value: 'mn',
    },
    {
      label: 'Bambara',
      value: 'bm',
    },
    {
      label: 'Chewa',
      value: 'ny',
    },
    {
      label: 'Ewe',
      value: 'ee',
    },
    {
      label: 'Fulah',
      value: 'ff',
    },
    {
      label: 'Kinyarwanda',
      value: 'rw',
    },
    {
      label: 'Kongo',
      value: 'kg',
    },
    {
      label: 'Kuanyama',
      value: 'kj',
    },
    {
      label: 'Lingala',
      value: 'ln',
    },
    {
      label: 'Luba-Katanga',
      value: 'lu',
    },
    {
      label: 'Ndebele do Norte',
      value: 'nd',
    },
    {
      label: 'Rundi',
      value: 'rn',
    },
    {
      label: 'Sotho do Sul',
      value: 'st',
    },
    {
      label: 'Swahili',
      value: 'sw',
    },
    {
      label: 'Swati',
      value: 'ss',
    },
    {
      label: 'Tswana',
      value: 'tn',
    },
    {
      label: 'Tsonga',
      value: 'ts',
    },
    {
      label: 'Twi',
      value: 'tw',
    },
    {
      label: 'Venda',
      value: 've',
    },
    {
      label: 'Wolof',
      value: 'wo',
    },
    {
      label: 'Xhosa',
      value: 'xh',
    },
    {
      label: 'Iorubá',
      value: 'yo',
    },
    {
      label: 'Zulu',
      value: 'zu',
    },
    {
      label: 'Kanuri',
      value: 'kr',
    },
    {
      label: 'Avaric',
      value: 'av',
    },
    {
      label: 'Checheno',
      value: 'ce',
    },
    {
      label: 'Abkhazian',
      value: 'ab',
    },
    {
      label: 'Quechua',
      value: 'qu',
    },
    {
      label: 'Birmanês',
      value: 'my',
    },
    {
      label: 'Chinês',
      value: 'zh',
    },
    {
      label: 'Dzongkha',
      value: 'dz',
    },
    {
      label: 'Sichuan Yi',
      value: 'ii',
    },
    {
      label: 'Tibetano',
      value: 'bo',
    },
    {
      label: 'Lao',
      value: 'lo',
    },
    {
      label: 'Thai',
      value: 'th',
    },
    {
      label: 'Zhuang',
      value: 'za',
    },
    {
      label: 'Guarani',
      value: 'gn',
    },
    {
      label: 'Azerbaijano',
      value: 'az',
    },
    {
      label: 'Bashkir',
      value: 'ba',
    },
    {
      label: 'Chuvash',
      value: 'cv',
    },
    {
      label: 'Cazaque',
      value: 'kk',
    },
    {
      label: 'Kirghiz',
      value: 'ky',
    },
    {
      label: 'Turcomenos',
      value: 'tk',
    },
    {
      label: 'Turco',
      value: 'tr',
    },
    {
      label: 'Tártaro',
      value: 'tt',
    },
    {
      label: 'Uighur',
      value: 'ug',
    },
    {
      label: 'Uzbeque',
      value: 'uz',
    },
    {
      label: 'Estónio',
      value: 'et',
    },
    {
      label: 'Finlandês',
      value: 'fi',
    },
    {
      label: 'Húngaro',
      value: 'hu',
    },
    {
      label: 'Komi',
      value: 'kv',
    },
    {
      label: 'Sami do Norte',
      value: 'se',
    },
    {
      label: 'Escandinavo',
      value: 'sc',
    },
  ],
};

export default languagesObjects;
