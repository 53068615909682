import { useEffect, useState } from 'react';

// Services
import logger from 'SERVICES/logger';
import { registerErrorCallback } from 'SERVICES/serverAPI';

// Hooks
import useAppInitializer from 'HOOKS/useAppInitializer';
import useActions from 'HOOKS/useActions';

// Store
import { appActions } from 'STORE/appSlice';

// Router
import TeamsRouter from 'ROUTES/TeamsRouter';

// Theme
import ThemeProvider from 'RESOURCES/theme/ThemeProvider';

const App = () => {
  // Initialize App
  const { isInitialized } = useAppInitializer();
  logger.info('App intialized: ', isInitialized);

  const setErrorTimestamp = useActions(appActions.setErrorTimestamp);

  // stores error DateTime
  const [errorValue, setErrorValue] = useState<number | undefined>(undefined);

  // error callback
  const errorCallback = () => {
    setErrorValue(Date.now);
  };

  // set error timeStamp when error value is changed i.e., when error occured
  useEffect(() => {
    if (errorValue && setErrorTimestamp) {
      // set error timeStamp in store
      setErrorTimestamp();
    }
  }, [errorValue]);

  // callback registration
  registerErrorCallback(errorCallback);

  if (!isInitialized) return null;

  return (
    <ThemeProvider>
      <TeamsRouter />
    </ThemeProvider>
  );
};

export default App;
