import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Services
import { useTranslation } from 'SERVICES/i18n';
import logger from 'SERVICES/logger';

// Hooks
import useSnackbar from 'HOOKS/useSnackbar';
import useActions from 'HOOKS/useActions';
import useMeetingDetails from 'HOOKS/useMeetingDetails';

// Constants
import { HTML_ELEMENT_POSITION, SNACKBAR_TYPE, USER_ROLE } from 'CONSTANTS/enum';
import { COPY_ELEMENT, LANGUAGE_PREFIX, TEAMS_LANGUAGE_RESOURCE } from 'CONSTANTS/teamsConstants';

// Store
import { teamsMeetingSelector as selector, teamsMeetingActions } from 'STORE/teamsMeetingSlice';

// Utils
import { ISubmitData } from 'UTILS/teamsInterface';

const usePreMeeting = () => {
  const showSnackbar = useSnackbar();
  const [editMode, setEditMode] = useState(true);
  const currentUserRole = useSelector(selector.currentUserRole);
  const teamsMeetingDetail = useSelector(selector.teamsMeetingDetail);

  const { t } = useTranslation(TEAMS_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.PREMEETING);
  const {
    isAuthorized,
    fetchMeetingDetails,
    loader,
    submitData,
    refetchMeetingByProvidingPermission,
  } = useMeetingDetails();
  const { updateMeetingDetailsUniversalAccess } = useActions(teamsMeetingActions);

  const fetchMeeting = async ({ usePermissionScope } = { usePermissionScope: false }) => {
    logger.debug('fetchMeeting', 'usePermissionScope:', usePermissionScope);
    const meetingDetails = usePermissionScope
      ? await refetchMeetingByProvidingPermission()
      : await fetchMeetingDetails();
    if (meetingDetails?.interpretation?.interpreterMeetings.length === 0) {
      // If there is not language show PreMeetingForm component
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  };

  useEffect(() => {
    fetchMeeting();
  }, []);

  const onSubmit = async ({
    languages,
    updatedLanguages,
    universalAccessEnabled,
    isPartialUpdate,
  }: ISubmitData) => {
    try {
      await submitData({
        languages,
        updatedLanguages,
        universalAccessEnabled,
        isPartialUpdate,
      });
      showSnackbar({ message: t('SUBMIT_REQ_SUCCESS_MSG'), type: SNACKBAR_TYPE.SUCCESS });
      await updateMeetingDetailsUniversalAccess(universalAccessEnabled);
      setEditMode(false);
    } catch (error: any) {
      logger.error('Error While submitting languages', error);
      showSnackbar({ message: error.message, type: SNACKBAR_TYPE.DANGER });
    }
  };

  const createCopyDescription = () => {
    const customLink = `${process.env.REACT_APP_URL}/participant/join/${teamsMeetingDetail?.meetingId}`;
    const formattedDescription = `
    <div id="${COPY_ELEMENT.CLIPBOARD_COPY_TEXT_SELECTOR_ID}">
      <qt text=black>
      <hr width="55%" align="left"/>
        <p>
          <font size=5>
            ${t('AUA_EMAIL_TITLE')}
          </font>
        </p>
        </br>
        <b>
          <p>
            ${t('AUA_EMAIL_DESCRIPTION')}
          </p>
        </b>
        <a href="${customLink}">
          <u>
            ${t('AUA_EMAIL_LINK_DISPLAY')}
          </u>
        </a>
        </br>
        <hr width="55%" align="left"/>
      </qt>
    </div>
    `;
    document
      .getElementsByClassName(COPY_ELEMENT.ORGANIZER_EDITABLE_PRE_FORM_CONTAINER)[0]
      ?.insertAdjacentHTML(HTML_ELEMENT_POSITION.beforeEnd, formattedDescription);
    return document.getElementById(COPY_ELEMENT.CLIPBOARD_COPY_TEXT_SELECTOR_ID);
  };

  const copyToClip = () => {
    const node: any = createCopyDescription();
    try {
      const selection: any = window.getSelection();
      const range: any = document.createRange();
      range.selectNodeContents(node);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand(COPY_ELEMENT.EXEC_COMMAND_COPY);
      showSnackbar({
        message: t('AUA_LINK_COPY_SUCCESS'),
        type: SNACKBAR_TYPE.SUCCESS,
      });
    } catch {
      showSnackbar({
        message: t('AUA_LINK_COPY_FAILURE'),
        type: SNACKBAR_TYPE.DANGER,
      });
    } finally {
      node.remove();
    }
  };

  return {
    isAuthorized,
    loader,
    editMode: editMode && currentUserRole === USER_ROLE.ORGANIZER,
    setEditMode,
    onSubmit,
    fetchMeeting,
    copyToClip,
    t,
  };
};

export default usePreMeeting;
