import { getIdToken } from 'FEATURES/authentication';

// Constant
import { TEAMS_ERROR } from 'CONSTANTS/teamsConstants';

// Hooks
import { useState } from 'react';

// Service
import logger from 'SERVICES/logger';

export const useAuthentication = () => {
  const [loading, setLoading] = useState(false);
  const [consentProvided, setConsentProvided] = useState(false);

  const authenticate = async () => {
    // show loader
    setLoading(true);
    try {
      // Aquiring the SSO token
      const idToken = await getIdToken(false);
      logger.debug('SSO Token aquired: ', idToken);
      setConsentProvided(true);
      // remove loader
      setLoading(false);
    } catch (error) {
      // SSO fails
      // prompt user to signIn 
      logger.error(TEAMS_ERROR.SIGNIN, error);
      // remove loader
      setLoading(false);
    }
  };

  return {
    isAuthorized: consentProvided,
    authenticate,
    loading
  };
};
