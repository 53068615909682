import { authentication, app } from '@microsoft/teams-js';

// Constants
import { CONSENT_STATE_KEY } from 'CONSTANTS/teamsConstants';

// Hooks
import { useEffect } from 'react';

// Utils
import localCache from 'UTILS/localCache';
import { IHash } from 'UTILS/teamsInterface';

const ClosePopup = () => {
  // Helper function that converts window.location.hash into a dictionary
  const getHashParameters = () => {
    const hashParams: IHash = {};
    window.location.hash
      .substring(1)
      .split('&')
      .forEach((item) => {
        const [key, value] = item.split('=');
        hashParams[key] = decodeURIComponent(value);
      });
    return hashParams;
  };

  // UseEffect to notify Authentication status
  useEffect(() => {
    app.initialize().then(() => {
      const hashParams = getHashParameters();
      // getting consent key stored by the Consent Popup
      const consentState = localCache.getItem(CONSENT_STATE_KEY);
      localCache.removeItem(CONSENT_STATE_KEY);

      if (hashParams?.state !== consentState) {
        authentication.notifyFailure('State verification failed');
        return;
      }

      if (hashParams.code) {
        // Notify useThemesAuth that authorization succeeded.
        authentication.notifySuccess(hashParams.toString());
      } else {
        authentication.notifyFailure(hashParams.toString());
      }
    });
  }, []);

  return (
    <div>
      <h1>Consent flow complete...</h1>
    </div>
  );
};
export default ClosePopup;
