import PropTypes from 'prop-types';
import { Flex, Text } from '@fluentui/react-northstar';

// Constants
import { LANGUAGE_PREFIX, TEAMS_LANGUAGE_RESOURCE } from 'CONSTANTS/teamsConstants';

// Component
import AkouoLogo from 'COMPONENTS/AkouoLogo';

// Services
import { useTranslation } from 'SERVICES/i18n';

// Utils
import { ITabFooter } from 'UTILS/teamsInterface';

// Styles
import './TabFooter.scss';

const TabFooter = ({ className }: ITabFooter) => {
  const { t } = useTranslation(TEAMS_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.COMMON);
  return (
    <Flex hAlign="end" vAlign="center" className={className}>
      <Text content={t('POWERED_BY')} />
      <span className="logo-style">
        <AkouoLogo height="60px" />
      </span>
    </Flex>
  );
};

TabFooter.propTypes = {
  className: PropTypes.string,
};

TabFooter.defaultProps = {
  className: null,
};
export default TabFooter;
