import { app } from '@microsoft/teams-js';
import { getIdToken as getTeamIdToken } from 'FEATURES/authentication/index';

// Constant
import { TEAMS_LANGUAGE_RESOURCE } from 'CONSTANTS/teamsConstants';

// Hooks
import { useEffect, useState } from 'react';
import useTeams from 'HOOKS/useTeams';

// Resource
import languages from 'RESOURCES/languages';

// Service
import { injectCallbackToGetAccessToken } from 'SERVICES/axiosInit';
import { addI18nResources, useTranslation } from 'SERVICES/i18n';
import logger from 'SERVICES/logger';

const useAppInitializer = () => {
  const { i18n } = useTranslation();
  const [isInitialized, setIsInitialized] = useState(false);
  const context = useTeams();

  // storing language part (not country code) from teams contex
  useEffect(() => {
    const appLocale = context?.app.locale;
    const teamsLocalLang = appLocale?.substring(0, appLocale?.indexOf('-'));
    i18n.changeLanguage(teamsLocalLang);
  }, [context]);

  // Initializing App and Localization Service
  useEffect(() => {
    try {
      setIsInitialized(true);
      app.notifySuccess();
      addI18nResources(TEAMS_LANGUAGE_RESOURCE, languages);
      injectCallbackToGetAccessToken(getTeamIdToken);
      logger.debug('App initialized');
    } catch (error) {
      logger.error(error);
    }
  }, []);

  return { isInitialized, context };
};

export default useAppInitializer;
