/* eslint-disable camelcase */
import { nanoid } from 'nanoid';
import { useEffect } from 'react';
import { ROUTES, ORIGIN, CONSENT_STATE_KEY, APP_CLIENT_ID } from 'CONSTANTS/teamsConstants';
import localCache from 'UTILS/localCache';
import logger from 'SERVICES/logger';
/**
 * This component is used to redirect the user to the Azure authorization endpoint from a popup
 * Learn more: https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-oauth2-auth-code-flow
 */
const ConsentPopup = () => {
  useEffect(() => {
    // Extracting scope query param from url which is passed from showGraphConsent() in authentication.js
    const urlParams = new URLSearchParams(window.location.search);
    const scope = urlParams.get('scope');
    logger.debug('Scope: ', scope);
    if (scope) {
      const uniqueState = nanoid(6);
      localCache.setItem(CONSENT_STATE_KEY, uniqueState);

      let queryParams: any = {
        client_id: APP_CLIENT_ID,
        response_type: 'code',
        response_mode: 'fragment',
        redirect_uri: `${ORIGIN}${ROUTES.CLOSE_POPUP}`,
        state: uniqueState,
        prompt: 'consent',
        scope,
      };

      const url = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?';

      queryParams = new URLSearchParams(queryParams).toString();
      const authorizeEndpoint = url + queryParams;
      // const authorizeEndpoint = URL.AUTH_URL + queryParams;
      // Redirect to the Azure authorization endpoint. When that flow completes, the user will be directed to auth-end
      // Go to ClosePopup.js
      window.location.assign(authorizeEndpoint);
    }
  }, []);

  return (
    <div>
      <h1>Redirecting to consent page...</h1>
    </div>
  );
};

export default ConsentPopup;
