import { useEffect, useState } from 'react';
import { CallClient } from '@azure/communication-calling';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';

// Services
import { useTranslation } from 'SERVICES/i18n';
import logger from 'SERVICES/logger';
import serverAPI from 'SERVICES/serverAPI';

// Hooks
import useSnackbar from 'HOOKS/useSnackbar';

// Constants
import { SNACKBAR_TYPE } from 'CONSTANTS/enum';
import { API_ENDPOINTS } from 'CONSTANTS/apiConstants';
import { LANGUAGE_PREFIX, TEAMS_LANGUAGE_RESOURCE } from 'CONSTANTS/teamsConstants';

// Utils
import { changeTeamsIncomingAudio } from 'UTILS/teamsMeetingUtils';

const useTeamsAcs = () => {
  const showSnackbar = useSnackbar();
  const [call, setCall] = useState<any>(null);
  const [loading, setLoading] = useState<any>(false);
  const [callAgent, setCallAgent] = useState<any>(null);
  const [acsSetupCompleted, setAcsSetupCompleted] = useState<any>(null);
  const [remoteParticipants, setRemoteParticipants] = useState<any>([]);
  const { t } = useTranslation(TEAMS_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.COMMON);

  const subscribeToRemoteParticipant = (participant: any) => {
    // check if participant is not already present
    if (!remoteParticipants.find((p: any) => p === participant)) {
      setRemoteParticipants((prevState: any) => [...prevState, participant]);
    }

    participant.on('stateChanged', () => {
      if (participant.state === 'Connected') {
        setRemoteParticipants((prevState: any) => [...prevState]);
      }
    });

    participant.on('isMutedChanged', () => {
      const isAllInterpreterMuted = call.remoteParticipants.every((rp: any) => rp.isMuted === true);
      changeTeamsIncomingAudio(!isAllInterpreterMuted); // false -> then mute else unmute
    });

    if (participant.isMuted === false) {
      // muted
      changeTeamsIncomingAudio(true);
    }
  };

  // Handle connection/call state change events and update the loading state
  const connectionStateChangedHandler = (connectionState: string) => {
    if (connectionState) {
      if (['Connected', 'Disconnected'].includes(connectionState)) setLoading(false);
      else if (connectionState === 'Connecting') setLoading(t('CONNECTING'));
      else if (connectionState === 'Disconnecting') setLoading(t('DISCONNECTING'));
    }
  };

  useEffect(() => {
    if (callAgent) {
      const connectionStateChanged = () => {
        const { connectionState } = callAgent;
        logger.debug(`ACS: connection state changed to ${connectionState}`);
        connectionStateChangedHandler(connectionState);
      };

      connectionStateChanged();
      callAgent.on('connectionStateChanged', connectionStateChanged);
    }
  }, [callAgent]);

  useEffect(() => {
    if (call) {
      const callStateChanged = () => {
        const { state } = call;
        logger.debug(`ACS: call state changed to ${state}`);
        connectionStateChangedHandler(state);
      };

      callStateChanged();
      call.on('stateChanged', callStateChanged);

      call.remoteParticipants.forEach((rp: any) => subscribeToRemoteParticipant(rp));

      call.on('remoteParticipantsUpdated', (e: any) => {
        e.added.forEach((p: any) => {
          logger.debug('participantAdded', p);
          subscribeToRemoteParticipant(p);
        });
        e.removed.forEach((p: any) => {
          logger.debug('participantRemoved', p);
          if (p.callEndReason) {
            logger.error(`Remote participant ${p.identifier} 
            disconnected: code: ${p.callEndReason.code}, subCode: ${p.callEndReason.subCode}.`);
          }
          setRemoteParticipants((preState: any) =>
            preState.filter((remoteParticipant: any) => remoteParticipant !== p),
          );
          if (p.isMuted === false) {
            changeTeamsIncomingAudio(false); // un-mute team audio
          }
        });
      });
    }
  }, [call]);

  const getAcsToken = async (userId: string) => {
    const response = await serverAPI.post(API_ENDPOINTS.ACS_ACCESS_TOKEN, { userId });
    return response.data.token;
  };

  const getDeviceManagerPermission = async (callClient: any) => {
    const deviceManager = await callClient.getDeviceManager();
    logger.debug('Device manager created now waiting for permission');
    await deviceManager.askDevicePermission({ audio: true });
    logger.debug('Returned from permission and good to go');
    setAcsSetupCompleted(true);
  };

  const setupLanguageCall = async (callClient: any, userId: string) => {
    const token = await getAcsToken(userId);
    const tokenCredential = new AzureCommunicationTokenCredential(token);
    const callAgentLocal = await callClient.createCallAgent(tokenCredential, {
      displayName: 'Participant', // generic name to all participant because we are using same userId for all participant in a meeting
    });

    callAgentLocal.on('callsUpdated', (e: any) => {
      e.added.forEach((_call: any) => {
        setCall(_call);
      });

      e.removed.forEach((callItem: any) => {
        if (call && call === callItem) {
          logger.error('Call Ended with reason ', call.callEndReason);
        }
      });
    });
    setCallAgent(callAgentLocal);
  };
  const loginToAcs = async (userId: string) => {
    const callClient = new CallClient();

    try {
      await Promise.all([
        getDeviceManagerPermission(callClient),
        setupLanguageCall(callClient, userId),
      ]);
    } catch (err: any) {
      logger.error('Error occured in Promise', err);
    }
  };

  const joinInterpreterMeeting = async (groupId: string) => {
    try {
      setLoading(t('CONNECTING'));
      const callOptions = {
        audioOptions: {
          muted: true,
        },
      };
      await callAgent?.join({ groupId }, callOptions);
    } catch (e) {
      logger.error('Failed to join a call', e);
      showSnackbar({ message: 'Fail to join the meeting', type: SNACKBAR_TYPE.DANGER }); // hardcode string
    }
  };

  const disconnectInterpreterMeeting = async () => {
    if (call) {
      setLoading(t('DISCONNECTING'));
      changeTeamsIncomingAudio(false); // unmute the team audio
      await call.hangUp();
    }
  };

  return {
    loginToAcs,
    joinInterpreterMeeting,
    disconnectInterpreterMeeting,
    loading,
    acsSetupCompleted,
  };
};

export default useTeamsAcs;
