import { configureStore } from '@reduxjs/toolkit';

// Store
import appSlice from 'STORE/appSlice';
import teamsMeetingSlice from 'STORE/teamsMeetingSlice';

const store = configureStore({
  reducer: {
    app: appSlice,
    teamsMeeting: teamsMeetingSlice,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
