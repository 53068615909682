import { Flex } from '@fluentui/react-northstar';

// Constant
import { THEME_MODE } from 'CONSTANTS/enum';

// Hooks
import useTheme from 'HOOKS/useTheme';

// Resource
import { ReactComponent as SignInDark } from 'RESOURCES/assets/sign-in-dark.svg';
import { ReactComponent as SignInLight } from 'RESOURCES/assets/sign-in-light.svg';

// Utils
import { ISigninButton } from 'UTILS/teamsInterface';

const MicrosoftSignInButton = ({ onClick, className }: ISigninButton) => {
  const { themeMode } = useTheme();
  return (
    <Flex className={`cursor-pointer ${className}`} padding="padding.medium">
      {themeMode === THEME_MODE.DEFAULT ? (
        <SignInLight onClick={onClick} />
      ) : (
        <SignInDark onClick={onClick} />
      )}
    </Flex>
  );
};

MicrosoftSignInButton.defaultProps = {
  className: '',
};

export default MicrosoftSignInButton;
