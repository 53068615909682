import { Loader } from '@fluentui/react-northstar';

// Utils
import { ILoader } from 'UTILS/teamsInterface';

// Styles
import './OverlayLoader.scss';

const OverlayLoader = ({ label }: ILoader) => {
  return (
    <div className="overlay-loader">
      <Loader label={{ content: label, align: 'center' }} />
    </div>
  );
};

export default OverlayLoader;
