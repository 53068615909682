import { authentication } from '@microsoft/teams-js';

// Constant
import { ROUTES, ORIGIN } from 'CONSTANTS/teamsConstants';

// Service
import logger from 'SERVICES/logger';

// One consent is provided the getAuthToken will provide the SSO token
// Silent is an optional flag indicating whether to attempt the token acquisition silently or allow a prompt to be shown.
export const getIdToken = async (silent = true) => {
    return new Promise((resolve, reject) => {
        authentication.getAuthToken({
            silent,
        }).then(
            (response) => resolve(response)
        )
            .catch(
                (error) => {
                    logger.error('Error: can get auth token: ', error)
                    reject(new Error(error))
                }
            )
    });
};

// Will open a new window and initiate Authentication process
export const showGraphConsent = async (scope: any) => {
    logger.debug('Scope [SHOW_GRAPH]: ', scope)
    return new Promise((resolve, reject) => {
        authentication.authenticate({
            url: `${ORIGIN}${ROUTES.CONSENT_POPUP}?scope=${scope}`,
            width: 600,
            height: 535,
        }).then(
            (response) => resolve(response)
        )
            .catch(
                (error) => {
                    logger.error('Error: can get auth token: ', error)
                    reject(new Error(error))
                }
            );
    });
};
