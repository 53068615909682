import PropTypes from 'prop-types';
import { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Flex, Text, Dropdown, Button, Checkbox } from '@fluentui/react-northstar';

// Constants
import { SNACKBAR_TYPE } from 'CONSTANTS/enum';
import {
  COPY_ELEMENT,
  LANGUAGE_PREFIX,
  LANGUAGES_SELECTED,
  TEAMS_LANGUAGE_RESOURCE,
} from 'CONSTANTS/teamsConstants';

// Services
import { useTranslation } from 'SERVICES/i18n';

// Utils
import { getLanguageNames, getLanguageList } from 'UTILS/languagesUtils';

// Store
import { selectInterpretationLanguages, teamsMeetingSelector } from 'STORE/teamsMeetingSlice';

// Styles
import './PreMeetingForm.scss';

// Hooks
import useSnackbar from 'HOOKS/useSnackbar';

// Utils
import { IPreMeetingForm } from 'UTILS/teamsInterface';

const PreMeetingForm = ({ handleSubmit, handleCancelButton, copyToClip }: IPreMeetingForm) => {
  const showSnackbar = useSnackbar();
  const { t } = useTranslation(TEAMS_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.PREMEETING);
  const [copyButtonText, setCopyButtonText] = useState(t('COPY_LINK_FOR_PARTICIPANTS'));

  const selectedInterpretationLanguages = useSelector(selectInterpretationLanguages);
  const teamsMeetingDetail = useSelector(teamsMeetingSelector.teamsMeetingDetail);
  const universalAccessEnabled = useSelector(teamsMeetingSelector.universalAccessEnabled);

  const [selectedLanguages, setSelectedLanguages] = useState(
    getLanguageNames(selectedInterpretationLanguages),
  );
  const [isChecked, setIsChecked] = useState(!!universalAccessEnabled);
  const interpretationLanguages = getLanguageList();
  const languages = useMemo(
    () =>
      interpretationLanguages.map((lang: any) => ({
        header: lang.label,
        value: lang.value,
        key: lang.value,
      })),
    [],
  );

  const onSubmit = () => {
    const langCodes = selectedLanguages.map((item: any) => item?.value);
    const updatedData: any = [];
    const isPartialUpdate = teamsMeetingDetail.interpretation.interpreterMeetings.length > 0;
    langCodes.forEach((lang: any) => {
      const existData = teamsMeetingDetail.interpretation.interpreterMeetings.find(
        (ele: any) => ele.language === lang,
      );
      if (existData) {
        updatedData.push({
          language: existData.language,
          id: existData.id,
        });
      } else {
        updatedData.push({ language: lang });
      }
    });

    return selectedLanguages.length < LANGUAGES_SELECTED
      ? showSnackbar({
          message: t('MINIMUM_LANGUAGES', { languageCount: LANGUAGES_SELECTED }),
          type: SNACKBAR_TYPE.DANGER,
        })
      : handleSubmit({
          languages: langCodes,
          updatedLanguages: updatedData,
          universalAccessEnabled: isChecked,
          isPartialUpdate,
        });
  };

  useEffect(() => {
    setCopyButtonText(t('COPY_LINK_FOR_PARTICIPANTS'));
  }, [isChecked]);

  return (
    <Flex
      padding="padding.medium"
      column
      className={COPY_ELEMENT.ORGANIZER_EDITABLE_FORM_CONTAINER}
      gap="gap.medium"
    >
      <Text
        className="meeting-title"
        content={t('CONFIGURE_INTERPRETATION_LANGUAGES')}
        weight="bold"
      />
      <Flex column className="dropdown-flex">
        <Text className="dropdown-label" content={t('ADD_LANGUAGES_LABEL')} />
        <Dropdown
          items={languages}
          placeholder={`${t('CHOOSE_LANG_DROPDOWN_MSG')}`}
          multiple
          getA11ySelectionMessage={{
            onAdd: (item: any) => {
              setSelectedLanguages((prevArray: any) => [...prevArray, item]);
              return '';
            },
            onRemove: (item: any) => {
              setSelectedLanguages((prevArray: any) =>
                prevArray.filter((lang: any) => lang.label !== item && lang.value !== item.value),
              );
              return '';
            },
          }}
          noResultsMessage={t('LANGUAGE_NOT_FOUND')}
          defaultActiveSelectedIndex={2}
          defaultValue={selectedLanguages.map((lang: any) => lang?.label)}
          search
          fluid
          inverted
        />
      </Flex>
      <Flex column>
        <Checkbox
          checked={isChecked}
          onChange={(e, data) => {
            setIsChecked(data?.checked || false);
          }}
          className="dropdown-label"
          label={t('AUA_TITLE')}
        />
        <Text className="aua-label-style">{t('AUA_DESCRIPTION1')}</Text>
        <Text content={t('AUA_POINT1')} className="aua-list-item" />
        <Text content={t('AUA_POINT2')} className="aua-list-item" />
        <Text className="style-pd-top">{t('AUA_DESCRIPTION2')}</Text>
        {isChecked && (
          <Flex className="copy-button">
            <Button content={copyButtonText} secondary onClick={copyToClip} />
          </Flex>
        )}
      </Flex>
      <Flex gap="gap.medium" className="submit-btn">
        <Button content={t('SUBMIT_LANG_REQ_BTN')} primary onClick={onSubmit} />
        <Button content={t('CANCEL_LANG_REQ_BTN')} onClick={handleCancelButton} />
      </Flex>
    </Flex>
  );
};

PreMeetingForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancelButton: PropTypes.func.isRequired,
};

export default PreMeetingForm;
