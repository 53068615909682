import { app } from '@microsoft/teams-js';

// getting Teams meeting Context
export const getTeamsContext = () => {
  return new Promise((resolve, reject) => {
    app.initialize().then(() => {
      app.getContext().then((context: app.Context) => {
        if (context) resolve(context);
        else reject(new Error('Unable to get teams context'));
      });
    });
  });
};
