import { Text, Flex } from '@fluentui/react-northstar';

// Components
import AkouoSupport from 'COMPONENTS/AkouoSupport';
import FullScreenLoader from 'COMPONENTS/FullScreenLoader';
import MicrosoftSignInButton from 'COMPONENTS/MicrosoftSignInButton';

// Features
import usePreMeeting from 'FEATURES/tab/preMeeting/hooks/usePreMeeting';
import PreMeetingForm from 'FEATURES/tab/preMeeting/preMeetingForm/PreMeetingForm';
import PreMeetingReview from 'FEATURES/tab/preMeeting/preMeetingReview/PreMeetingReview';

// Styles
import './PreMeeting.scss';

const PreMeeting = () => {
  const { editMode, loader, onSubmit, setEditMode, isAuthorized, fetchMeeting, copyToClip, t } =
    usePreMeeting();
  if (loader.isLoading) return <FullScreenLoader label={loader.message} />;
  if (!isAuthorized) {
    return (
      <Flex fill hAlign="center" vAlign="center" column>
        <Text content={t('AUTH_ERROR_MSG')} align="center" />
        <AkouoSupport size="small" align="center" className="padding-top-style" />
        <MicrosoftSignInButton onClick={() => fetchMeeting({ usePermissionScope: true })} />
      </Flex>
    );
  }

  return (
    <div className="pre-meeting-container padding-style">
      {editMode ? (
        <PreMeetingForm
          handleSubmit={onSubmit}
          handleCancelButton={() => setEditMode(false)}
          copyToClip={copyToClip}
        />
      ) : (
        <PreMeetingReview handleEditClick={() => setEditMode(true)} copyToClip={copyToClip} />
      )}
    </div>
  );
};

export default PreMeeting;
