import { useEffect, useState } from 'react';
import { app } from '@microsoft/teams-js';

// Services
import { useTranslation } from 'SERVICES/i18n';
import logger from 'SERVICES/logger';
import { fetchMeetingDetailsAPI } from 'SERVICES/api';

// Hooks
import useActions from 'HOOKS/useActions';
import useSnackbar from 'HOOKS/useSnackbar';
import useTeams from 'HOOKS/useTeams';
import useTeamsAcs from 'FEATURES/tab/inMeeting/hooks/useTeamsAcs';

// Constants
import { ERROR } from 'CONSTANTS/apiConstants';
import { SNACKBAR_TYPE } from 'CONSTANTS/enum';
import { CALL_TYPE, LANGUAGE_PREFIX, TEAMS_LANGUAGE_RESOURCE } from 'CONSTANTS/teamsConstants';

// Utils
import { checkInterpretationIsSupported } from 'UTILS/acsUtils';
import meetingLocalStorage from 'UTILS/meetingLocalStorage';
import { getLanguageList } from 'UTILS/languagesUtils';

// Store
import { teamsMeetingActions } from 'STORE/teamsMeetingSlice';

const addLanguageLabelKey = (languages: any, interpretationLanguages: []) => {
  return languages.map((lang: any) => {
    const item: any = interpretationLanguages.find((_item: any) => lang.language === _item.value);
    return {
      id: lang.id,
      label: item.label,
      value: lang.language,
    };
  });
};

const useInMeeting = () => {
  const { t } = useTranslation(TEAMS_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.INMEETING);
  const context: app.Context = useTeams() as app.Context;
  const showSnackbar = useSnackbar();
  const [apiFetchComplete, setApiFetchComplete] = useState<any>(undefined);
  const [showCountDownTimer, setCountDownTimer] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<any>(null);
  const [showUnsupportedPopup, setUnsupportedPopup] = useState(false);
  const [interpreterMeetings, setInterpreterMeetings] = useState([
    {
      id: CALL_TYPE.FLOOR,
      label: t('NO_INTERPRETATION_OPTION'),
      value: CALL_TYPE.FLOOR,
    },
  ]);
  const [showUnsupportedDialog, setShowUnsupportedDialog] = useState(false); // Showing Interpretation unsupported dialog
  const setMeetingDetails = useActions(teamsMeetingActions.setMeetingDetails);

  const interpretationLanguages: any = getLanguageList();

  const {
    loginToAcs,
    joinInterpreterMeeting,
    disconnectInterpreterMeeting,
    acsSetupCompleted,
    loading,
  } = useTeamsAcs();

  const handleLanguageSelection = async (lang: any) => {
    if (lang.value === CALL_TYPE.FLOOR) {
      setSelectedLanguage(null);
      if (selectedLanguage) {
        await disconnectInterpreterMeeting();
        meetingLocalStorage.remove();
      }
    } else if (selectedLanguage?.value !== lang.value) {
      if (selectedLanguage) {
        await disconnectInterpreterMeeting();
      }
      setSelectedLanguage(lang);
      await joinInterpreterMeeting(lang.id);
      if (context?.meeting && context?.user) {
        meetingLocalStorage.save(context?.meeting?.id, context?.user?.id, lang.value);
      }
    }
  };

  const connectToPreviousJoinMeetingIfAny = async (
    meetingId: string,
    userId: string,
    languagesFetched: any,
  ) => {
    const languageSelected = meetingLocalStorage.get(meetingId, userId);
    if (languageSelected) {
      const language = languagesFetched.find((lang: any) => lang.value === languageSelected);
      if (language) await handleLanguageSelection(language);
    }
  };

  useEffect(() => {
    if (acsSetupCompleted && interpreterMeetings && context?.meeting && context?.user) {
      // after acs setup complete do this
      connectToPreviousJoinMeetingIfAny(context.meeting.id, context.user.id, interpreterMeetings);
    }
  }, [acsSetupCompleted, interpreterMeetings, context]);

  const initializeInMeeting = async ({ meeting, user }: any) => {
    try {
      setApiFetchComplete(false);
      // AKMSTEAMS-1219: send user id in fetchMeetingDetailsAPI
      const meetingDetails = await fetchMeetingDetailsAPI(meeting?.id, '', user?.id);
      setMeetingDetails(meetingDetails);
      setApiFetchComplete(true);
      const { interpretation } = meetingDetails;
      const languages = interpretation.interpreterMeetings;
      if (languages.length !== 0) {
        const meetingsWithLabel = addLanguageLabelKey(languages, interpretationLanguages);
        // AKMSTEAMS-461
        // meetingsWithLabel.push({
        //   id: CALL_TYPE.FLOOR,
        //   label: t('NO_INTERPRETATION_OPTION'),
        //   value: CALL_TYPE.FLOOR,
        // });
        setInterpreterMeetings([...meetingsWithLabel, ...interpreterMeetings]);

        if (checkInterpretationIsSupported(context.app.host.clientType)) {
          if (meetingsWithLabel.length !== 0) {
            setCountDownTimer(true);
            await loginToAcs(interpretation.participantId);
            setCountDownTimer(false);
          }
        } else {
          setShowUnsupportedDialog(true);
        }
      }
    } catch (error: any) {
      if ([ERROR.MEETING_NOT_FOUND, ERROR.INVALID_MEETING_REQUEST].includes(error?.code)) {
        // No languages found for this meeting. Do nothing
        logger.debug('New Meeting: No information is present on server');
      } else {
        logger.error('Error while initializing in-meeting', error);
        showSnackbar({ message: error.message, type: SNACKBAR_TYPE.DANGER });
      }
      setApiFetchComplete(true);
    }
  };

  useEffect(() => {
    if (context) initializeInMeeting(context);
  }, [context]);

  const handleUnsupportedInterpretation = () => setUnsupportedPopup(true);

  return {
    t,
    loading,
    selectedLanguage,
    showCountDownTimer,
    interpreterMeetings,
    setUnsupportedPopup,
    showUnsupportedPopup,
    apiFetchComplete,
    handleLanguageSelection: showUnsupportedDialog
      ? handleUnsupportedInterpretation
      : handleLanguageSelection,
  };
};

export default useInMeeting;
