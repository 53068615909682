import { Flex, Text, Loader } from '@fluentui/react-northstar';

// Components
import AkouoLogo from 'COMPONENTS/AkouoLogo';
import HyperLink from 'COMPONENTS/HyperLink';
import MicrosoftSignInButton from 'COMPONENTS/MicrosoftSignInButton';

// Constants
import { AKOUO_CONNECT_URL, AKOUO_SUPPORT_URL } from 'CONSTANTS/teamsConstants';

// Hooks
import useTabConfig from 'FEATURES/tabConfig/hooks/useTabConfig';

const TabConfig = () => {
  const { t, isAuthorized, authenticate, loading } = useTabConfig();

  // if loading then render loader
  if (loading) {
    return (
      <Flex fill hAlign="center" vAlign="center" column gap="gap.smaller">
        <Loader label={t('LOADING_TEAMS_ACCOUNT')} />
      </Flex>
    );
  }

  return (
    <Flex fill hAlign="center" vAlign="center" column gap="gap.smaller">
      <AkouoLogo width="200px" />
      <Text content={t('WELCOME_MESSAGE')} size="larger" />
      <Text content={t('TAGLINE')} size="larger" />
      {!isAuthorized && <Text content={t('AUTH_ERROR_MSG')} align="center" />}
      <Text size="small">
        {t('CONNECT_LINK')}
        <HyperLink link={AKOUO_CONNECT_URL} />
      </Text>
      <Text size="small">
        {t('SUPPORT_LINK')}
        <HyperLink link={AKOUO_SUPPORT_URL} />
      </Text>
      {!isAuthorized && <MicrosoftSignInButton onClick={authenticate} />}
    </Flex>
  );
};

export default TabConfig;
