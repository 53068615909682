import { useFluentContext } from '@fluentui/react-northstar';
import { useSelector } from 'react-redux';

// getting MS Teams theme from store and theme object for adding styles to FluentUI components
const useTheme = () => {
  const themeMode = useSelector((store:any) => store.app.themeMode);
  const { theme } = useFluentContext();
  return { themeMode, theme };
};

export default useTheme;
