// Features
import Tab from 'FEATURES/tab/Tab';
import TabConfig from 'FEATURES/tabConfig/TabConfig';
import ConsentPopup from 'FEATURES/consent/ConsentPopup';
import ClosePopup from 'FEATURES/consent/ClosePopup';

// Constant
import { ROUTES } from 'CONSTANTS/teamsConstants';

// Utils
import { IRouteType } from 'UTILS/teamsInterface';

const teamsRoutes: IRouteType[] = [
  { path: ROUTES.TAB, element: <Tab /> },
  { path: ROUTES.CONFIG, element: <TabConfig /> },
  { path: ROUTES.CONSENT_POPUP, element: <ConsentPopup /> },
  { path: ROUTES.CLOSE_POPUP, element: <ClosePopup /> },
];

export default teamsRoutes;
