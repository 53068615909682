import {
  Provider,
  teamsTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
} from '@fluentui/react-northstar';

// Constants
import { THEME_MODE } from 'CONSTANTS/enum';

// Hooks
import { ReactNode, useEffect, useState } from 'react';
import useActions from 'HOOKS/useActions';

// Service
import logger from 'SERVICES/logger';

// Store
import { appActions } from 'STORE/appSlice';

// Utils
import { app } from '@microsoft/teams-js';
import { getTeamsContext } from 'UTILS/teamsUtils';

const style = { height: '100vh', background: 'transparent' };

const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const dispatchAppThemeMode = useActions(appActions.setAppThemeMode);
  const [theme, setTheme] = useState(teamsTheme);

  // handling theme changes
  const themeChangeHandler = (themeMode: string) => {
    logger.debug(`Theme is change to ${themeMode}`);
    dispatchAppThemeMode(themeMode || THEME_MODE.DEFAULT);
    switch (themeMode) {
      case THEME_MODE.DARK:
        setTheme(teamsDarkTheme);
        break;
      case THEME_MODE.CONTRAST:
        setTheme(teamsHighContrastTheme);
        break;
      case THEME_MODE.DEFAULT:
      default:
        setTheme(teamsTheme);
    }
  };

  const setThemeAndRegisterThemeHandler = async () => {
    const context: any = await getTeamsContext();
    themeChangeHandler(context.app.theme);
    app.registerOnThemeChangeHandler(themeChangeHandler);
  };

  // setting Up theme for the application
  useEffect(() => {
    setThemeAndRegisterThemeHandler();
  }, []);

  return (
    <Provider theme={theme} style={style}>
      {children}
    </Provider>
  );
};

export default ThemeProvider;
