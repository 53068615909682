// Constants
import { API_CONTEXT_TEAMS, API_ENDPOINTS } from 'CONSTANTS/apiConstants';

// Service
import serverAPI from 'SERVICES/serverAPI';

/**
 * Fetch Meeting details from backend.
 * @param {String} meetingId: Teams Meeting Id
 * @param {String} code: Authentication code received from auth code flow
 * @param {String} userId: user id
 * @returns response data
 */
export const fetchMeetingDetailsAPI = async (meetingId: string, code?: any, userId = '') => {
  const url = `${API_ENDPOINTS.MEETINGS}/${meetingId}/${API_CONTEXT_TEAMS}/${userId}`;
  const response = await serverAPI.get(url, { params: { code } });
  return response.data;
};

/**
 *
 * @param {string} meetingId: Teams Meeting Id
 * @param {[string]} languages:
 * @returns
 */
export const putMeeting = async (interpretationData: any) => {
  return serverAPI.put(API_ENDPOINTS.MEETINGS, interpretationData);
};
