// Services
import { useTranslation } from 'SERVICES/i18n';

// Constants
import languagesObjects from 'CONSTANTS/languagesObjects';

export const getLanguageNames = (languageCodes: any) => {
  const { i18n } = useTranslation();
  return (languagesObjects as any)[i18n.language].filter((item: any) =>
    languageCodes.includes(item.value),
  );
};

export const getLanguageList = () => {
  const { i18n } = useTranslation();
  return (languagesObjects as any)[`${i18n.language}`];
};

export const selectLanguageForCaptions = (captionEvents: any, selectedLanguage: string) => {
  const DEFAULT_CAPTIONING_LANGUAGE = 'en';
  return (
    // caption language = interpretation language
    captionEvents.find((language: any) => {
      return language.sourcelanguageid === selectedLanguage;
    }) ||
    // OR caption language = English default, only if available in the list of captioning languages
    captionEvents.find((language: any) => {
      return language.sourcelanguageid === DEFAULT_CAPTIONING_LANGUAGE;
    }) ||
    // OR caption language = first language available in the list of captioning languages
    captionEvents[0]
  );
};
