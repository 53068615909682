import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@fluentui/react-northstar';

// Store
import { appActions } from 'STORE/appSlice';

// Style
import './Snackbar.scss';

const Snackbar = () => {
  const snackbar = useSelector((store: any) => store.app.snackbar);
  const dispatch = useDispatch();

  if (snackbar.show) {
    setTimeout(() => dispatch(appActions.hideSnackbar()), snackbar.duration);
  }

  if (snackbar.show) {
    return (
      <div className="snackbar">
        <Alert
          className="snackbar-alert"
          fitted
          content={snackbar.message}
          {...{ [snackbar.type]: true }}
        />
      </div>
    );
  }
  return null;
};

export default Snackbar;
