import { useState } from 'react';

// Services
import logger from 'SERVICES/logger';
import { useTranslation } from 'SERVICES/i18n';
import { fetchMeetingDetailsAPI, putMeeting } from 'SERVICES/api';

// Constants
import { ERROR, STATUS_CODE } from 'CONSTANTS/apiConstants';
import { USER_ROLE } from 'CONSTANTS/enum';
import { TEAMS_LANGUAGE_RESOURCE, LANGUAGE_PREFIX } from 'CONSTANTS/teamsConstants';

// Hooks
import useActions from 'HOOKS/useActions';

// Utils
import { getTeamsContext } from 'UTILS/teamsUtils';
import { ISubmitData } from 'UTILS/teamsInterface';

// Store
import { teamsMeetingActions } from 'STORE/teamsMeetingSlice';

// Features
import { showGraphConsent } from 'FEATURES/authentication/authentication';

const useMeetingDetails = () => {
  const [loading, setLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [consentProvided, setConsentProvided] = useState(false);
  const [graphScope, setGraphScope] = useState();
  const { t } = useTranslation(TEAMS_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.PREMEETING);
  const { setCurrentUserRole, setMeetingDetails, updateInterpretingLanguages } =
    useActions(teamsMeetingActions);

  const setLoadingWithMessage = (message: string) => {
    setLoading(true);
    setLoadingMessage(message);
  };

  const setUserRoleInStore = async (organizer: any, userObjectId: string) => {
    if (organizer?.id === userObjectId) {
      logger.info('Current user is an Organizer');
      setCurrentUserRole(USER_ROLE.ORGANIZER);
    } else {
      logger.info('Current user is not an Organizer');
      setCurrentUserRole(USER_ROLE.PARTICIPANT);
    }
  };

  const refetchMeetingByProvidingPermission = async (scope?: any) => {
    logger.info('refetchMeetingByProvidingPermission');
    let meetingDetails = null;
    const context: any = await getTeamsContext();
    try {
      const consent: any = await showGraphConsent(scope || graphScope);
      logger.debug('Authentication code: ', consent?.code);
      meetingDetails = await fetchMeetingDetailsAPI(context?.meeting?.id, consent?.code);
      setMeetingDetails(meetingDetails);
      await setUserRoleInStore(meetingDetails?.organizer, context?.user?.id);
      setConsentProvided(true);
    } catch (error: any) {
      if (error.code === ERROR.MEETING_NOT_FOUND) {
        // Current user is participant
        setConsentProvided(true);
      } else {
        setConsentProvided(false);
      }
    }
    return meetingDetails;
  };

  const fetchMeetingDetails = async () => {
    const context: any = await getTeamsContext();
    setLoadingWithMessage(t('LOAD_MEETING_INFO_LOADER_MSG'));
    let meetingDetails = null;
    try {
      meetingDetails = await fetchMeetingDetailsAPI(context?.meeting?.id);
      setMeetingDetails(meetingDetails);
      await setUserRoleInStore(meetingDetails?.organizer, context?.user?.id);
      setConsentProvided(true);
    } catch (error: any) {
      logger.error(error, 'Error while fetching meeting Details');
      if (error?.code === ERROR.INVALID_MEETING_REQUEST) {
        if (error.resCode === STATUS_CODE.NOT_FOUND) {
          setConsentProvided(true);
        } else {
          logger.debug(`setGraphScope ${error?.data?.scope}`);
          setGraphScope(error?.data?.scope);
          meetingDetails = await refetchMeetingByProvidingPermission(error?.data?.scope);
        }
      } else if (error?.code === ERROR.MEETING_NOT_FOUND) {
        // Current user is participant. so we don't require graph consent
        setConsentProvided(true);
      }
    }
    setLoading(false);
    return meetingDetails;
  };

  const submitData = async ({
    languages,
    updatedLanguages,
    universalAccessEnabled,
    isPartialUpdate,
  }: ISubmitData) => {
    setLoadingWithMessage(t('SUBMIT_REQ_LOADER_MSG'));
    try {
      const context: any = await getTeamsContext();
      await putMeeting({
        meetingId: context?.meeting?.id,
        languages: isPartialUpdate ? updatedLanguages : languages,
        universalAccessEnabled,
        isPartialUpdate,
      });
      updateInterpretingLanguages(languages);
      logger.info('Your request has been successfully submitted.');
      setLoading(false);
    } catch (error: any) {
      logger.error('Error While submitting languages', error);
      if (error?.code === ERROR.INVALID_MEETING_REQUEST) {
        logger.debug(`setGraphScope ${error?.data?.scope}`);
        setGraphScope(error.data.scope);
        setConsentProvided(false);
      }
      setLoading(false);
      throw error;
    }
  };

  return {
    isAuthorized: consentProvided,
    loader: {
      isLoading: loading,
      message: loadingMessage,
    },
    fetchMeetingDetails,
    submitData,
    refetchMeetingByProvidingPermission,
  };
};

export default useMeetingDetails;
