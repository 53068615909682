// Constants
import { AUDIT_LOGGING_API } from 'CONSTANTS/apiConstants';

// Services
import logger from 'SERVICES/logger';

export const logAuditEvent = (request: any) => {
  try {
    navigator.sendBeacon(AUDIT_LOGGING_API, JSON.stringify(request));
  } catch (error) {
    logger.error(error);
  }
};
