/* eslint-disable no-console */

const logger = {
    info: (...messages: any) => console.info(new Date(), ...messages),
    debug: (...messages: any) => console.debug(new Date(), ...messages),
    error: (...messages: any) => console.error(new Date(), ...messages),
};

export default logger;
  /**
* Now logger is only a wrapper around console.log()
*/