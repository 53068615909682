import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Flex, Text, Button, Checkbox } from '@fluentui/react-northstar';

// Services
import { useTranslation } from 'SERVICES/i18n';

// Store
import {
  teamsMeetingSelector as selector,
  selectInterpretationLanguages,
} from 'STORE/teamsMeetingSlice';

// Constants
import { USER_ROLE } from 'CONSTANTS/enum';
import { TEAMS_LANGUAGE_RESOURCE, LANGUAGE_PREFIX } from 'CONSTANTS/teamsConstants';

// Components
import AkouoSupport from 'COMPONENTS/AkouoSupport';

// Utils
import { getLanguageNames } from 'UTILS/languagesUtils';
import { IPreMeetingReview } from 'UTILS/teamsInterface';

const ParticipantInformation = ({ noLanguages, t }: IPreMeetingReview) => {
  return (
    <Flex column style={{ width: 'max-content' }}>
      <span>
        {noLanguages
          ? t('NO_LANGUAGES_CONFIGURED_PARTICIPANT')
          : t('LANGUAGES_CONFIGURED_PARTICIPANT')}
      </span>
      <AkouoSupport />
    </Flex>
  );
};

ParticipantInformation.propTypes = {
  noLanguages: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

const PreMeetingView = ({
  handleEditClick,
  copyToClip,
}: {
  handleEditClick: any;
  copyToClip: any;
}) => {
  const selectedLanguages = useSelector(selectInterpretationLanguages);
  const noLanguages = selectedLanguages?.length === 0;
  const currentUserRole = useSelector(selector.currentUserRole);
  const universalAccessEnabled = useSelector(selector.universalAccessEnabled);
  const isUserOrganizer = currentUserRole === USER_ROLE.ORGANIZER;

  const { t } = useTranslation(TEAMS_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.PREMEETING);

  return (
    <Flex column gap="gap.large" padding="padding.medium">
      <Flex gap="gap.small" column>
        <Text size="large" weight="bold" content={t('MEETING_LANGUAGES_LABEL')} />
        <Text styles={{ opacity: 0.68 }}>
          {!isUserOrganizer && (
            <ParticipantInformation noLanguages={selectedLanguages.length === 0} t={t} />
          )}
        </Text>
        {noLanguages ? (
          <Flex>{t('NO_LANGUAGES_CONFIGURED_ORGANIZER')}</Flex>
        ) : (
          <Flex wrap>
            <Text className="language-name">
              {getLanguageNames(selectedLanguages)
                .map((item: any) => item.label)
                .join(', ')}
            </Text>
          </Flex>
        )}
      </Flex>

      {isUserOrganizer && (
        <Flex column gap="gap.smaller">
          <Checkbox
            className="dropdown-label"
            label={t('AUA_TITLE')}
            style={{ maxWidth: '20em', pointerEvents: 'none', paddingTop: '1rem' }}
            checked={!!universalAccessEnabled}
          />
          {universalAccessEnabled && (
            <Flex style={{ marginBottom: '0.5rem' }}>
              <Button content={t('COPY_LINK_FOR_PARTICIPANTS')} secondary onClick={copyToClip} />
            </Flex>
          )}
          <Text styles={{ opacity: 0.5 }} content={t('EDIT_REQUEST_LABEL')} />
          <div>
            <Button content={t('EDIT_LANG_BTN')} primary onClick={() => handleEditClick()} />
          </div>
        </Flex>
      )}
    </Flex>
  );
};

PreMeetingView.propTypes = {
  handleEditClick: PropTypes.func.isRequired,
};

export default PreMeetingView;
