import { createSlice } from '@reduxjs/toolkit';

// Constants
import { USER_ROLE } from 'CONSTANTS/enum';

const initialState = {
  teamsMeetingDetail: {},
  currentUserRole: USER_ROLE.ANONYMOUS,
  interpreterMeetings: [],
  participantAcsId: undefined,
  universalAccessEnabled: false,
};

const teamsMeetingSlice = createSlice({
  name: 'teamsMeeting',
  initialState,
  reducers: {
    setMeetingDetails(state, action) {
      state.teamsMeetingDetail = action.payload;
      if (action.payload?.interpretation) {
        const { interpreterMeetings, participantId } = action.payload.interpretation;
        state.interpreterMeetings = interpreterMeetings;
        state.participantAcsId = participantId;
      }
      state.universalAccessEnabled = action.payload?.universalAccessEnabled;
    },
    setCurrentUserRole(state, action) {
      state.currentUserRole = action.payload;
    },
    updateInterpretingLanguages(state, action) {
      state.interpreterMeetings = action.payload.map((value: any) => ({
        language: value,
      }));
    },
    updateMeetingDetailsUniversalAccess(state, action) {
      state.universalAccessEnabled = action.payload;
    },
  },
});

export default teamsMeetingSlice.reducer;

export const teamsMeetingActions = { ...teamsMeetingSlice.actions };

export const teamsMeetingSelector = {
  teamsMeetingDetail: (store: any) => store.teamsMeeting.teamsMeetingDetail,
  currentUserRole: (store: any) => store.teamsMeeting.currentUserRole,
  universalAccessEnabled: (store: any) => store.teamsMeeting.universalAccessEnabled,
  languages: (store: any) =>
    store.teamsMeeting.interpreterMeetings.map((item: any) => item.language),
};

export const selectInterpretationLanguages = (state: any) =>
  state.teamsMeeting.interpreterMeetings.map((item: any) => item.language);
