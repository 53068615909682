// Constant
import { THEME_MODE } from 'CONSTANTS/enum';

// Hooks
import useTheme from 'HOOKS/useTheme';

// Resource
import { ReactComponent as AkouoLogoBlack } from 'RESOURCES/assets/akouo-logo-black.svg';
import { ReactComponent as AkouoLogoWhite } from 'RESOURCES/assets/akouo-logo-white.svg';

// Utils
import { IImageDimentions } from 'UTILS/teamsInterface';

const AkouoLogo = ({ height, width }: IImageDimentions) => {
  const { themeMode } = useTheme();
  return themeMode === THEME_MODE.DEFAULT ? (
    <AkouoLogoBlack width={width} height={height} />
  ) : (
    <AkouoLogoWhite width={width} height={height} />
  );
};

export default AkouoLogo;
