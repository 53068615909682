import { useEffect } from 'react';
import { pages } from '@microsoft/teams-js';

// Constant
import { ROUTES, ORIGIN, TEAMS_LANGUAGE_RESOURCE, LANGUAGE_PREFIX } from 'CONSTANTS/teamsConstants';

// Hooks
import { useAuthentication } from 'FEATURES/authentication';

// Service
import { useTranslation } from 'SERVICES/i18n';

const useTabConfig = () => {
  const { t } = useTranslation(TEAMS_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.TAB_CONFIG);
  const { isAuthorized, authenticate, loading } = useAuthentication();

  // initiating Authentication process
  useEffect(() => {
    authenticate();
  }, []);

  // Notifying the Authentication status
  // setting up URLs for the Tab Iframe
  useEffect(() => {
    if (isAuthorized) {
      pages.config.registerOnSaveHandler((saveEvent) => {
        pages.config.setConfig({
          contentUrl: `${ORIGIN}${ROUTES.TAB}`,
          websiteUrl: `${ORIGIN}${ROUTES.TAB}`,
        });
        saveEvent.notifySuccess();
      });
      pages.config.setValidityState(true);
    }
  }, [isAuthorized]);

  return { isAuthorized, authenticate, t, loading };
};

export default useTabConfig;
